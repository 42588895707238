export enum Role {
  OPERATOR = 'operator',
  ADMIN = 'admin',
  SUPERADMIN = 'superadmin',
}

export const roleHierarchy = [Role.OPERATOR, Role.ADMIN, Role.SUPERADMIN];

export interface AccessToken {
  exp: number;
  'custom:role': Role;
}

export interface User {
  email: string;
  role: Role;
  firstName: string;
  lastName: string;
  agencies?: string[];
}

export interface Invitation {
  email: string;
  role: Role;
}
