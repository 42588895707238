import { useEffect } from 'react';
import ReconnectingWebsocket from 'reconnecting-websocket';

class CustomEvent<Payload> extends Event {
  payload: Payload;

  constructor(type: string, payload: Payload) {
    super(type);
    this.payload = payload;
  }
}

type TMessage<TData> = {
  eventName: string;
  data: TData;
};

export const createWebsocketConnection = (token: string | null): ReconnectingWebsocket => {
  const connection = new ReconnectingWebsocket(
    `${import.meta.env.VITE_APP_WEBSOCKET_URL}?Auth=${token}` as string,
    undefined,
    { maxRetries: 1, debug: true },
  );

  connection.onmessage = (event) => {
    const wsEvent = new CustomEvent('wsMessage', JSON.parse(event.data));
    document.dispatchEvent(wsEvent);
  };

  return connection;
};

/**
 * Subscribe to all websocket events, if `eventName` match and the `validate` function
 * pass against the event payload, execute `callback` with the event payload.
 *
 * The `validate` and `callback` will never be updated, they will be executed with their
 * implementation at the hook instantiation.
 *
 * To use a *reactive* value in `validate` and `callback`, use a `useRef` value.
 *
 * @debt refacto: Use a dedicated websocket library to be able to use *reactive* values and callbacks
 */
export const useFeedback = <TData,>({
  eventName,
  validate = () => true,
  callback,
}: {
  eventName: string;
  validate?: (data: TData) => boolean;
  callback: (data: TData) => void;
}) => {
  useEffect(() => {
    const subscribe = (event: Event) => {
      const { payload } = event as CustomEvent<TMessage<TData>>;
      if (payload.eventName === eventName && validate(payload.data)) {
        callback(payload.data);
      }
    };

    document.addEventListener('wsMessage', subscribe);

    return () => {
      document.removeEventListener('wsMessage', subscribe);
    };
    // should only be run at component mount so an empty dependency array is specified
    // eslint-disable-next-line
  }, []);
};
