import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { useEffect } from 'react';

const GROWTHBOOK_API_URL = 'https://cdn.growthbook.io';

const growthBook = new GrowthBook({
  // enableDevMode: true allows you to use the Chrome DevTools Extension to test/debug.
  enableDevMode: import.meta.env.VITE_APP_ENV !== 'prod',
  apiHost: GROWTHBOOK_API_URL,
  clientKey: import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY,
});

export const FeatureFlagsProvider = ({ children }: { children?: JSX.Element }): JSX.Element => {
  useEffect(() => {
    growthBook.loadFeatures();
  }, []);

  return <GrowthBookProvider growthbook={growthBook}>{children}</GrowthBookProvider>;
};
