export enum BroadcastChannel {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  GTFS_RT = 'GTFS_RT',
  TWITTER = 'TWITTER',
  PUSH = 'PUSH',
  TRANSACTIONAL_SMS = 'TRANSACTIONAL_SMS',
  TRANSACTIONAL_EMAIL = 'TRANSACTIONAL_EMAIL',
  WEBSITE_BANNER = 'WEBSITE_BANNER',
}
