import { SettingFilled } from '@ant-design/icons';
import React, { useContext } from 'react';
import { useMatch } from 'react-router';

import logo from 'assets/icons/notify_logo_white.svg';
import { DirectionContext } from 'components/Contexts';
import SVG from 'components/SVG';
import { Paths } from 'routes';
import useRole from 'services/networking/role';
import { Role } from 'types/user';

import { HeaderContainer, Link, Logo, Menu, NetworkName, Settings } from './Header.style';

const Header: React.FC = () => {
  const userRole = useRole();
  const match = useMatch('/networks/:networkName/*');
  const direction = useContext(DirectionContext);
  return (
    <HeaderContainer direction={direction}>
      <Link to={Paths.NETWORK_LIST}>
        <Logo>
          <SVG src={logo} />
        </Logo>
      </Link>
      <Menu>
        {match && match.pathname !== Paths.NETWORK_CREATION && match.params.networkName && (
          <Link to={Paths.ALERT_LIST.replace(':networkName', match.params.networkName)}>
            <NetworkName>{match.params.networkName}</NetworkName>
          </Link>
        )}
        {[Role.ADMIN, Role.SUPERADMIN].some((role: Role) => role === userRole) &&
          match &&
          match.pathname !== Paths.NETWORK_CREATION &&
          match.params.networkName && (
            <Link to={Paths.SETTINGS.replace(':networkName', match.params.networkName)}>
              <Settings direction={direction}>
                <SettingFilled />
              </Settings>
            </Link>
          )}
      </Menu>
    </HeaderContainer>
  );
};

export default Header;
