import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import { DirectionContextType } from 'components/Contexts/directionContext';
import {
  colorUsage,
  device,
  fontFamily,
  fontSize,
  fontWeight,
  getSpacing,
  lineHeight,
} from 'stylesheet';
import { Env } from 'types/env';

export const HeaderContainer = styled.header<{ direction: DirectionContextType }>`
  align-items: center;
  text-align: center;
  background-color: ${colorUsage.header.background[
    (import.meta.env.VITE_APP_ENV as Env) ?? 'default'
  ]};
  flex: 0 0 ${getSpacing(16)};
  padding: 0 ${getSpacing(8)};
  position: relative;
  direction: ${({ direction }) => direction};

  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;
HeaderContainer.displayName = 'HeaderContainer';

export const Logo = styled.div`
  width: ${getSpacing(25)};
  height: ${getSpacing(9)};
  display: none;

  @media ${device.tablet} {
    display: block;
  }
`;
Logo.displayName = 'Logo';

export const Link = styled(RouterLink)`
  text-decoration: none;
`;

export const Title = styled.h1`
  vertical-align: middle;
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.main};
  font-size: ${fontSize.Xlarge};
  line-height: ${getSpacing(16)};
  color: ${colorUsage.text.coloredBackground};
  margin: 0;
`;
Title.displayName = 'Title';

export const Menu = styled.div`
  display: flex;
  align-items: center;
`;
Menu.displayName = 'Menu';

export const NetworkName = styled.h1`
  font-family: ${fontFamily.main};
  font-size: ${fontSize.Xlarge};
  line-height: ${lineHeight.large};
  color: ${colorUsage.text.coloredBackground};
  margin: 0;
  display: none;

  @media ${device.tablet} {
    display: block;
  }
`;
Title.displayName = 'NetworkName';

export const Settings = styled.div<{ direction: DirectionContextType }>`
  width: ${getSpacing(5)};
  height: ${getSpacing(5)};
  margin-left: ${({ direction }) => (direction === 'ltr' ? getSpacing(5) : 0)};
  margin-right: ${({ direction }) => (direction === 'rtl' ? getSpacing(5) : 0)};
  color: ${colorUsage.text.coloredBackground};
  position: absolute;
  top: calc(50% - ${getSpacing(2.5)});
  right: ${getSpacing(10.5)};

  @media ${device.tablet} {
    position: static;
  }
`;
