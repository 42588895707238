import React from 'react';
import { useAsync } from 'react-use';

import { NetworkFeaturesContext } from 'components/Contexts';
import { NetworkFeaturesContextType } from 'components/Contexts/networkFeaturesContext';
import { useRequiredParams } from 'hooks/useParams';
import client from 'services/networking/client';
import { INetwork } from 'types/network';

export const NetworkFeaturesProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { networkName } = useRequiredParams<'networkName'>(['networkName']);

  const { value: network } = useAsync<() => Promise<INetwork>>(() => {
    return client.get(`/networks/${networkName}`);
  });

  const networkFeaturesContextValue: NetworkFeaturesContextType = {
    languages: network?.languages || [],
    isAlertImportEnabled: network?.features?.alertsImport || false,
    areFiltersEnabled: network?.features?.filters || false,
    isAutomatedTranslationEnabled: network?.features?.automatedTranslation || false,
  };

  return (
    <NetworkFeaturesContext.Provider value={networkFeaturesContextValue}>
      {children}
    </NetworkFeaturesContext.Provider>
  );
};
