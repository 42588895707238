import React from 'react';

export type NetworkFeaturesContextType = {
  languages: string[];
  isAlertImportEnabled?: boolean;
  areFiltersEnabled?: boolean;
  isAutomatedTranslationEnabled?: boolean;
};

const defaultNetworkFeaturesContext: NetworkFeaturesContextType = {
  // TODO: refactor to create dedicated network context
  // instead of one containing the features only.
  languages: [],
  isAlertImportEnabled: false,
  areFiltersEnabled: false,
  isAutomatedTranslationEnabled: false,
};
export const NetworkFeaturesContext = React.createContext<NetworkFeaturesContextType>(
  defaultNetworkFeaturesContext,
);
