import styled from 'styled-components';

import { borderRadius, colorUsage, device, fontFamily, getSpacing } from 'stylesheet';

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
RootContainer.displayName = 'RootContainer';

export const PageContent = styled.main`
  background-color: ${colorUsage.body.background};
  border-radius: ${borderRadius.large};
  padding: ${getSpacing(6)} ${getSpacing(2)};
  flex-grow: 1;
  font-family: ${fontFamily.main};

  @media ${device.tablet} {
    padding: ${getSpacing(6)} ${getSpacing(24)};
  }
`;
PageContent.displayName = 'PageContent';
