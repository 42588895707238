/* eslint-disable max-lines -- class are difficult to split */
import jwt_decode from 'jwt-decode';
import request from 'superagent';

import { Paths } from 'routes';
import { AccessToken } from 'types/user';

import { captureErrorWithSentry } from './errorHandling';
import { tokenHasExpired } from './tokenExpiration';

const backendBaseUrl = import.meta.env.VITE_APP_API_BASE_URL || '';

type Method = 'get' | 'post' | 'put' | 'patch' | 'delete';

class Client {
  baseUrl: string;
  withCredentials: boolean;
  agent: request.SuperAgentStatic & request.Request;
  tokenKey = 'token';
  refreshTokenKey = 'refreshToken';

  constructor(baseUrl: string, withCredentials = true) {
    this.baseUrl = baseUrl;
    this.withCredentials = withCredentials;
    this.agent = request.agent();
    this.agent.accept('application/json');
    if (withCredentials) {
      this.agent.withCredentials();
    }
  }

  // eslint-disable-next-line complexity -- this is readable and complex to extract. We could extract the promise creation, but I rather keep it in the request method
  async request(
    method: Method,
    endpoint: string,
    data: Record<string, unknown> | Blob | null = null,
    checkToken = true,
  ) {
    if (this.withCredentials) {
      // Checking token validity, refreshing it if necessary.
      if (checkToken) {
        await this.checkToken();
      }
    }

    const url = /^https?:\/\//.test(endpoint) ? endpoint : `${this.baseUrl}${endpoint}`;
    let promise = this.agent[method](url);

    const token = this.getToken();
    if (token && this.withCredentials) {
      promise = promise.set('Authorization', `Bearer ${token}`);
    }

    if (['post', 'put', 'patch'].includes(method) && data) {
      promise = promise.send(data);
    }

    try {
      const { body } = await promise;
      return body;
    } catch (error) {
      captureErrorWithSentry(error, { endpoint, method });
      throw error;
    }
  }

  getToken() {
    return localStorage.getItem(this.tokenKey);
  }

  getRefreshToken() {
    return localStorage.getItem(this.refreshTokenKey);
  }

  getUserRole() {
    const userToken = this.getToken();
    return this.decodeRole(userToken);
  }

  decodeRole(token: string | null) {
    if (!token) {
      return;
    }
    const parsedToken = jwt_decode<AccessToken>(token);
    return parsedToken['custom:role'];
  }

  updateToken(token?: string) {
    if (token) {
      localStorage.setItem(this.tokenKey, token);
    } else {
      localStorage.removeItem(this.tokenKey);
    }

    const roleUpdateEvent = new Event('roleUpdate');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // looks like the Event type is incomplete
    // https://stackoverflow.com/questions/51386266/override-typescript-declaration-type-to-declare-static-method-on-the-event-class
    roleUpdateEvent.role = this.decodeRole(token);
    document.dispatchEvent(roleUpdateEvent);
  }

  updateRefreshToken(refreshToken?: string) {
    if (refreshToken) {
      localStorage.setItem(this.refreshTokenKey, refreshToken);
      return;
    }
    localStorage.removeItem(this.refreshTokenKey);
  }

  redirectToSSO() {
    if (Paths.AUTHENTICATION_CALLBACK !== window.location.pathname) {
      const authUrl = import.meta.env.VITE_APP_API_AUTH_URL;
      if (!authUrl) {
        alert('No auth url found in environment variable, please fill it in .env file.');
        return;
      }
      window.location.replace(authUrl);
    }
  }

  /** This function assess the access token is still valid, if not it refreshes it. */
  async checkToken() {
    // Remove tokens and stop if no refresh token
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      this.updateToken();
      this.redirectToSSO();
    }

    // Try refresh if no token
    const token = this.getToken();
    if (!token) {
      await this.refreshToken();
      return;
    }

    // Try refresh if expired token
    const parsedToken = jwt_decode<AccessToken>(token);
    if (tokenHasExpired(parsedToken)) {
      await this.refreshToken();
    }
  }

  get(endpoint: string) {
    return this.request('get', endpoint);
  }

  post(endpoint: string, data: Record<string, unknown>) {
    return this.request('post', endpoint, data);
  }

  put(endpoint: string, data: Record<string, unknown> | Blob) {
    return this.request('put', endpoint, data);
  }

  delete(endpoint: string) {
    return this.request('delete', endpoint);
  }

  async login(data: Record<string, unknown>) {
    const { id_token, refresh_token } = await this.post('/token', data);
    this.updateToken(id_token);
    this.updateRefreshToken(refresh_token);
    return id_token;
  }

  async logout() {
    const result = await this.post('/auth/jwt/logout', {});
    return result;
  }

  async refreshToken() {
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      this.updateToken();
      this.redirectToSSO();
      return;
    }
    try {
      const { id_token } = await this.request(
        'post',
        '/refresh',
        { refresh_token: refreshToken },
        false,
      );
      this.updateToken(id_token);
    } catch (error) {
      this.updateToken();
      this.updateRefreshToken();
      this.redirectToSSO();
    }
  }

  async uploadFile(url: string, file: Blob) {
    return await this.request('put', url, file, false);
  }
}

const client = new Client(backendBaseUrl);
export const authenticationClient = new Client(backendBaseUrl, false);
export const githubApiClient = new Client('https://api.github.com', false);

export default client;
