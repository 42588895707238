/* eslint-disable max-lines */
import { BroadcastChannel } from 'types/broadcastChannels';

import { Translations } from './types';

const arTranslations: Translations = {
  languages: {
    fr: ' الفرنسية',
    en: ' الإنجليزية',
    it: 'Italian',
    br: ' البريطانية',
    ar: ' العربية',
  },
  alert: {
    number: 'رقم التنبيه',
    'creation-date': 'تاريخ الإنشاء',
    'created-by': 'منشىء التنبيه',
    cause: {
      label: 'السبب',
      ACCIDENT: 'حادث',
      TECHNICAL_PROBLEM: 'حادث تقني',
      MAINTENANCE: 'الصيانة',
      CONSTRUCTION: 'أشغال',
      STRIKE: 'اضراب',
      DEMONSTRATION: 'تظاهرة',
      WEATHER: 'طقس',
      HOLIDAY: 'عطلة',
      POLICE_ACTIVITY: 'نشاط الشرطة',
      MEDICAL_EMERGENCY: 'المستعجلات الطبية',
      OTHER_CAUSE: 'سبب آخر',
      UNKNOWN_CAUSE: 'سبب غير معروف',
    },
    effect: {
      label: 'تأثير',
      NO_SERVICE: 'خدمة غير متوفرة',
      SIGNIFICANT_DELAYS: 'تأخيرات كبيرة',
      DETOUR: 'انحراف',
      REDUCED_SERVICE: 'خدمة مخفضة',
      STOP_MOVED: 'توقف متنقل',
      MODIFIED_SERVICE: 'خدمة معدلة',
      ADDITIONAL_SERVICE: 'خدمة اضافية',
      OTHER_EFFECT: 'تأثير آخر',
      UNKNOWN_EFFECT: 'تأثير غير معروف',
    },
    'import-type': {
      new: 'new',
      update: 'update',
      closure: 'closure',
      no_change: 'no change',
      problem: 'problem',
      processing: 'processing',
      ready_to_import: 'ready to import',
    },
    'start-date': ' بداية الاضطراب',
    'end-date': ' نهاية الاضطراب',
    'route-type': {
      '0': 'Tramway',
      '1': ' مترو',
      '2': ' قطار',
      '3': 'Bus',
      '4': 'Ferry',
      '5': 'Tramway à traction par câble',
      '6': 'Télécabine ou téléphérique',
      '7': 'Funiculaire',
      '100': 'Service de chemin de fer',
      '101': 'Service de chemin de fer à grande vitess',
      '102': 'Grandes lignes de chemin de fer',
      '103': 'Service de chemin de fer transrégional',
      '104': 'Service de transport de voitures par chemin de fer',
      '105': 'Service de chemin de fer à couchettes',
      '106': 'Service de chemin de fer régional',
      '107': 'Service de chemin de fer touristique',
      '108': "Navette ferroviaire (au sein d'un complexe)",
      '109': 'Chemin de fer de banlieue',
      '110': 'Service de chemin de fer de substitution',
      '111': 'Service de chemin de fer spécial',
      '112': 'Service de transport de camions par chemin de fer',
      '113': 'Service de chemin de fer (toute catégorie)',
      '114': 'Service de chemin de fer transnational',
      '115': 'Service de transport de véhicules par chemin de fer',
      '116': 'Chemin de fer à crémaillère',
      '117': 'Service de chemin de fer supplémentaire',
      '200': 'Service de cars',
      '201': 'Service de cars internationaux',
      '202': 'Service de cars nationaux',
      '203': 'Service de navette par cars',
      '204': 'Service de cars régionaux',
      '205': 'Service de cars spéciaux',
      '206': 'Service de visite touristique en car',
      '207': 'Service de cars touristiques',
      '208': 'Service de cars de banlieue',
      '209': 'Service de cars (toute catégorie)',
      '400': 'Service de chemin de fer urbain',
      '401': 'Service de chemin de fer métropolitain',
      '402': 'Service de transport souterrain',
      '403': 'Service de chemin de fer urbain',
      '404': 'Service de chemin de fer urbain (toute catégorie)',
      '405': 'Monorail',
      '700': "Service d'autobus",
      '701': "Service d'autobus régional",
      '702': "Service d'autobus express",
      '703': "Service d'autobus desservant des arrêts",
      '704': "Service d'autobus local",
      '705': "Service d'autobus nocturne",
      '706': 'Service de car postal',
      '707': "Service d'autobus pour passagers présentant des besoins spéciaux",
      '708': "Service d'autobus pour personnes à mobilité réduite",
      '709': "Service d'autobus pour personnes à mobilité réduite (déclarées comme telles)",
      '710': 'Autobus panoramique',
      '711': 'Service de navette par autobus',
      '712': 'Bus scolaire',
      '713': 'Bus scolaire et à usage public',
      '714': "Service de substitution par autobus (remplacement d'un service de chemin de fer)",
      '715': 'Autobus à la demande',
      '716': "Service d'autobus (toute catégorie)",
      '717': 'Service de taxi partagé',
      '800': 'Service de trolleybus',
      '900': 'Service de tramway',
      '901': 'Service de tramway urbain',
      '902': 'Service de tramway local',
      '903': 'Service de tramway régional',
      '904': 'Service de tramway touristique',
      '905': 'Service de navette par tramway',
      '906': 'Service de tramway (toute catégorie)',
      '907': 'Tramway à traction par câble',
      '1000': 'Service de transport fluvial',
      '1100': 'Service de transport aérien',
      '1200': 'Service de ferry',
      '1300': 'Service de téléphérique',
      '1400': 'Service de funiculaire',
      '1500': 'Service de taxis',
      '1501': 'Service de taxis communaux',
      '1502': 'Service de taxi fluvial',
      '1503': 'Service de taxis en gare de chemin de fer',
      '1504': 'Service de vélotaxi',
      '1505': 'Service de taxis réglementé',
      '1506': 'Service de location de véhicules particuliers',
      '1507': 'Service de taxis (toute catégorie)',
      '1700': 'Services divers',
    },
    channel: {
      EMAIL: 'حملة البريد الإلكتروني',
      SMS: 'حملة الرسائل القصيرة',
      TRANSACTIONAL_SMS: 'رسالة قصيرة',
      TRANSACTIONAL_EMAIL: 'البريد الإلكتروني',
      GTFS_RT: ' موقع الكتروني + تطبيق + خرائط غوغل',
      TWITTER: 'تويتر/X',
      PUSH: ' دفع الإخطار',
      WEBSITE_BANNER: 'راية الموقع الإلكتروني',
    },
    channelContact: {
      TRANSACTIONAL_SMS: 'رسالة',
      TRANSACTIONAL_SMS_PLURAL: 'الرسائل',
      TRANSACTIONAL_EMAIL: 'البريد الإلكتروني',
      TRANSACTIONAL_EMAIL_PLURAL: 'رسائل البريد الإلكتروني',
    },
    broadcastStatus: {
      DRAFT: ' مسودة',
      ON_GOING: ' جار',
      PROGRAMMED: 'To come',
      SENT: ' أرسل',
      SKIPPED: 'تم تخطي',
      ERROR: ' مشكل',
      CANCELED: 'ألغيت',
    },
    broadcastStatusTooltip: {
      SKIPPED: {
        TWITTER: 'لم يتم بث هذا التحديث على Twitter لأن محتوى هذه القناة كان هو نفسه البث السابق',
        TRANSACTIONAL_SMS: 'لم يتم البث عبر الرسائل القصيرة لعدم وجود مشتركين',
        TRANSACTIONAL_EMAIL: 'لم يتم التسليم على البريد الإلكتروني لعدم وجود مشتركين',
      },
    },
  },
  component: {
    header: {
      title: 'DAIT',
    },
    'alert-information-overview': {
      title: 'معلومة حول الإنذار',
    },
    'alert-routes-overview': {
      title: ' خط متاثر',
    },
    'routes-selection': {
      'select-all': 'اختر كل شىء',
      title: ' خطوط',
      error: {
        required: ' المرجو ملء سطر واحد على الأقل',
      },
      'see-routes': ' انظر السطور',
      'select-routes': '{routeType} - حدد الأسطر المعنية',
      validate: 'تحقق',
      'route-types': 'وسائط النقل',
    },
    'alert-routes-card': {
      'no-route-selected': 'لم يتم تحديد صف',
    },
    'alert-template-form': {
      'template-name': {
        title: 'Template name',
        name: {
          label: 'Name',
          error: {
            EMPTY_NAME: 'Please enter a name',
            INVALID_NAME: 'Pease enter a different name',
          },
        },
      },
      'alert-information': {
        title: 'Alert information',
        cause: {
          placeholder: 'Cause',
          label: 'Cause',
        },
        effect: {
          placeholder: 'Impact',
          label: 'Impact',
        },
      },
      channels: {
        title: 'Communication channels',
      },
    },
    'channels-configuration': {
      'delete-language': 'مسح',
      'confirm-deletion': 'تاكيد',
      'cancel-deletion': 'إلغاء',
      'confirm-deletion-text': 'هل أنت متأكد أنك تريد حذف هذه اللغة',
      'alert-content': {
        'title-first': 'محتوى التنبيه',
        'title-other': '{index} محتوى التنبيه - لغة',
        'translation-label': 'توليد ترجمة تلقائيًا',
        'translation-warning':
          'تحذير! قد تحتوي الترجمات التلقائية في بعض الأحيان على أخطاء. لذلك من المهم التحقق من المحتوى قبل بث تنبيهك',
        'translation-error':
          'خدمة الترجمة الآلية غير متوفرة. يرجى المحاولة مرة أخرى لاحقاً أو استخدام حلول الترجمة البديلة.',
        'translation-truncated':
          'تتجاوز الترجمة المقترحة عدد الأحرف المسموح به. لذلك من المهم التحقق من المحتوى قبل بث تنبيهك.',
      },
      enrich: 'القناة {channel} - الإثراء',
      'add-language': 'أضف لغة بث جديدة',
      language: {
        label: 'لغة النشر',
        error: {
          required: 'الرجاء إضافة لغة النشر لهذا التنبيه',
        },
      },
      save: 'حفظ',
      object: {
        label: ':موضوع',
        help: 'سيتم استخدام هذا الحقل كموضوع للبريد الإلكتروني، وعنوان إشعار الدفع، وعنوان للتنبيه المرسل على الموقع الالكتروني، و التطبيق المحمول و خلاصة GTFS-RT.',
        error: {
          required: 'المرجو إدخال موضوع لهذا التنبيه',
          'max-length': 'الرجاء إدخال موضوع أقل من 60 حرفًا',
        },
      },
      [BroadcastChannel.WEBSITE_BANNER]: {
        label: 'محتوى راية موقع الويب ({language})',
        note: 'قم بتكوين وإنشاء لافتة على موقع الويب الخاص بك',
        bannerTitle: {
          label: 'عنوان الشريط',
          description: 'رسالة مرئية على شريط المعلومات',
        },
        pageTitle: {
          label: 'العنوان',
          description: 'عنوان الصفحة المرتبطة بشريط المعلومات',
        },
        bannerContent: {
          label: 'الوصف',
          description: 'محتوى الصفحة المرتبطة بشريط المعلومات',
        },
      },
      [BroadcastChannel.TRANSACTIONAL_EMAIL]: {
        label: 'محتوى البريد الإلكتروني  ({language})',
        note: '.قم بتحرير المحتوى وإعداد بريد إلكتروني تجريبي، استلم تنبيهك كما سيظهر لمستخدميك',
        test: {
          error: '!فشل',
          errorDetails: 'لم يتم إرسال البريد الإلكتروني. يرجى المحاولة مرة أخرى.',
          sending: '…الإرسال قيد التقديم',
          sendTest: '.استلام بريد إلكتروني تجريبي',
          sent: 'تم الإرسال',
          sentDetails: 'بريدك الاختباري متاح في صندوق البريد الخاص بك',
        },
      },
      'short-description': {
        label: 'وصف قصير (الرسائل القصيرة ، دفع الإخطارات)',
        error: {
          required: 'المرجو إدخال محتوى التنبيه الخاص بك',
          'max-length': 'المرجو إدخال وصف قصير أقل من 152 حرفًا',
        },
      },
      'long-description': {
        label:
          'وصف طويل (موقع الكتروني + تطبيق + خرائط غوغل ، البريد الإلكتروني ، تويتر ، صفحة شعار الموقع الإلكتروني)',
        error: {
          required: 'المرجو إدخال محتوى التنبيه الخاص بك',
          'max-length': 'الرجاء إدخال وصف طويل لا يقل عن 1200 حرف',
        },
        note: 'Please note that you can format the content of your alert on the email channel. To do so, select the email channel in the communication channels below and click on the edit icon on the right of the table.',
      },
      'twitter-enrich-subtext':
        'قم بإثراء منشوراتك على تويتر من خلال إنشاء منشورات متصلة لتحديث التنبيه أو بإضافة صور إلى تنبيهك.',
      'twitter-history-title': 'تاريخ تغريدات التنبيه',
      'twitter-history-empty': 'لم يتم نشر تغريدات بعد. اكتب رسالة جديدة لبدء المحادثة',
      'twitter-description': {
        label: 'تغريدة جديدة',
        error: {
          required: 'المرجو إدخال محتوى التنبيه الخاص بك',
          'max-length': 'يُرجى إدخال وصف أقل من 280 حرفاً',
        },
      },
      'twitter-image-description': {
        label: 'إضافة وصف',
        note: 'حسِّن إمكانية الوصول إلى منشورك ورؤيته من خلال إضافة وصف لصورتك!',
      },
      'rich-text': {
        'link-title': 'Insert a link',
        'link-name': 'Text to display',
        'link-target': 'Link',
        insert: 'Insert',
      },
      upload: {
        title: 'زيدو وثيقة ولا رابط للموقع',
        label: 'إضافة ملف PDF',
        button: 'تحميل ملف',
        copyInfo: 'تم نسخ URL في الحافظة الخاصة بك',
        copyError: 'النسخ و اللصق لم يتم',
        tip1: "إذا قمت بتحميل مستند ، فسيتم إدراجه تلقائيًا في القنا' موقع الكتروني' + تطبيق + خرائط غوغل.",
        tip2: 'إذا كنت ترغب في ضمه إلى قنوات أخرى ، فانسخ والصق عنوان URL ،للخطة في محتوى القنوات التي .تختارها',
        linkLabel: 'إضافة رابط URL',
        linkPlaceholder: 'https://example.com',
        linkTip: 'سيتم إضافة هذا الرابط إلى محتوى التنبيه المرسل إلى وسائل الإعلام المختلفة',
        tooltip:
          'أضف ملفاً أو عنوان URL إلى تنبيهك. سيكون هذا العنصر متاحًا في جميع وسائطك. يمكنك فقط تضمين رابط أو ملف.',
        'invalid-url': 'شكرًا على إدخال عنوان URL صحيح',
      },
      uploadImage: {
        button: 'انقر لإضافة ملف',
        label: 'إضافة صورة',
        supportedFiles: 'JPEG, JPG, PNG الملفات المدعومة',
        size: 'يتم عرض الصور بنسبة 16:9، أو 600 بكسل في 335 بكسل، في موجز تويتر. وعند النقر عليها، يتم تكبيرها إلى 1200 بكسل في 675 بكسل.',
        sizeError: 'يتجاوز الملف الذي تم تحميله الحد الأقصى للحجم المسموح به (5 ميغابايت).',
      },
      channels: {
        label: 'إدارة قنوات الاتصال الخاصة بك',
        'twitter-multilingual-error': 'لا يمكن نشر الرسالة إلا على تويتر في {defaultLanguage}',
        error: {
          required: 'المرجو تحديد قناة بث واحدة على الأقل',
        },
        'push-error':
          'لبث تنبيهك على قناة "دفع الإخطار "، يجب تحديد قناة " موقع الكتروني + تطبيق + خرائط غوغل',
        empty: 'ا توجد قناة متاحة',
        enrich: 'غنيمة',
        info: 'في هذا القسم يمكنك إدارة محتوى جميع قنوات التواصل والإضافة إليها قبل بث تنبيهك. انقر على "إثراء" لإدارة محتوى كل قناة!',
        name: 'قناة',
        message: 'رسالة',
        'message-type': {
          SHORT: 'قصير',
          LONG: 'طويل',
          CUSTOM: 'مخصص',
        },
        update: 'الأعمال',
      },
    },
    'broadcast-date-configuration': {
      'confirm-programmation': 'Confirm',
      'cancel-programmation': 'Cancel',
      title: 'Program the broadcast of the alert',
      'broadcast-before-today': 'Please select a time and date in the future',
      'broadcast-after-end': 'Please schedule your alert prior to the end date of the disruption',
    },
  },
  page: {
    'alert-creation': {
      'template-selection': {
        'use-template': 'Use a template',
        confirm: 'Confirm',
        cancel: 'Cancel',
        content: 'Steps 1 and 3 will be pre-filled with information from the template',
        select: 'Select a template',
        errorLabel: 'Please select a template',
      },
      steps: {
        information: 'معلومات التنبيه',
        routes: 'الخطوط المتأثرة',
        channels: 'قنوات التواصل',
        summary: 'التحقق',
      },
      information: {
        error: {
          ALERT_ALREADY_PUBLISHED: 'تم بث التنبيه',
        },
      },
      'routes-selection': {
        agency: 'Agencies: ',
        error: {
          ALERT_ALREADY_PUBLISHED: 'تم بث التنبيه',
        },
      },
      'channels-configuration': {
        error: {
          ALERT_ALREADY_PUBLISHED: 'تم بث التنبيه',
          TWITTER_MESSAGE_TOO_LONG: 'يجب أن تحتوي رسالة تويتر على أقل من 280 حرفًا',
        },
      },
      cause: {
        placeholder: 'سبب',
        label: 'سبب',
        error: {
          required: 'المرجو تحديد سبب التنبيه',
        },
      },
      effect: {
        placeholder: 'تأثير',
        label: 'تأثير',
        error: {
          required: 'المرجو تحديد تأثير الإنذار',
        },
      },
      period: {
        title: 'فترة تشهد اضطراب',
        'start-date': {
          label: ' تاريخ البدء ',
          error: {
            required: 'المرجو تحديد تاريخ بدء فترة الاضطراب',
          },
          help: '  اختر "اليوم" إذا كان الاضطراب قيد التقدم، أو حدد موعدًا لاحقًا إذا كنت تريد اعلام المسافرين بأي اضطراب مستقبلي. ',
        },
        'end-date': {
          label: ' تاريخ النهاية ',
          help: ' تاريخ الانتهاء هو التاريخ الذي لن يكون بعده تنبيهك مرئيًا للمسافرين. ',
          error: {
            beforeNow: ' المرجو تحديد تاريخ بعد التاريخ الحالي ',
            beforeStartDate: ' المرجو تحديد تاريخ بعد التاريخ البدء ',
            incomplete: ' المرجو إكمال تاريخ الانتهاء ',
          },
        },
      },
      summary: {
        object: ' الموضوع : ',
        externalLink: 'ملف أو عنوان URL مرتبط : ',
        broadcastLater: 'Program broadcast',
        broadcast: 'Broadcast now',
        error: {
          END_DATE_BEFORE_TODAY: 'The end date of the disruption is earlier than the current date.',
          ALERT_ALREADY_PUBLISHED: 'Alert has already been issued',
          END_DATE_BEFORE_BROADCAST_DATE: 'Disruption end date is before broadcast date',
          BROADCAST_DATE_BEFORE_TODAY: 'The broadcast date is before today',
        },
        channels: {
          name: 'قناة',
          message: ' رسالة ',
        },
      },
      next: ' التالي ',
    },
    'alert-detail': {
      title: ' تفاصيل التنبيه ',
      'broadcast-history': {
        title: ' عمليات البث السابقة ',
        'title-future': 'Future broadcast',
        panel: {
          header: {
            INITIAL: '{date} - أول بث',
            ADDITIONAL: '{date} - تحديث ',
          },
          body: {
            object: ' الموضوع:',
            externalLink: ' ملف أو عنوان URL مرتبط :',
            channel: ' قناة ',
            message: ' رسالة ',
            'broadcast-status': ' حالة الإرسال ',
          },
        },
      },
    },
    'alert-import': {
      title: 'استيراد ملف',
      steps: {
        publish: 'ينشر',
        'select-a-file': 'حدد ملف',
      },
      publish: {
        agency: 'Agency',
        cancel: 'Cancel import',
        publish: 'Publish alerts',
        confirm: {
          message: 'Are you sure you want to publish the alerts for the following agencies?',
          confirm: 'Confirm',
          cancel: 'Cancel',
          'error-message': 'There has been a problem with the Import',
          help: 'Please contact your administrator or IT Support',
          successTitle: 'The alerts have been imported successfully in Notify!',
          successMessage:
            'You can update or close these alerts directly in Notify or through a new file import',
          ok: 'Ok',
        },
        effect: 'Impact',
        endDate: 'Disruption end',
        importType: 'Upload status',
        location: 'File row {row}, column {column}: ',
        modal: {
          'created-alerts': '{current}/{final} alerts have been created',
          'updated-alerts': '{current}/{final} alerts have been updated',
          'closed-alerts': '{current}/{final} alerts have been closed',
          'unchanged-alerts': '{current}/{final} alerts have not been changed',
        },
        'imported-alerts': '{count} {count, plural, one {alert} other {alerts}} will be imported',
        'updated-alerts': '{count} {count, plural, one {alert} other {alerts}} will be updated',
        'closed-alerts': '{count} {count, plural, one {alert} other {alerts}} will be closed',
        'not-changed-alerts':
          '{count} {count, plural, one {alert} other {alerts}} will not be changed',
        'new-alerts': '{count} {count, plural, one {alert} other {alerts}} will be created',
        replace: 'Replace import file',
        results: 'Results',
        routes: 'Lines',
        rowNumber: 'File row',
        startDate: 'Disruption start',
        problem: 'Problem',
        problems: {
          'broadcast-date-in-past': 'The broadcast date cannot be in the past',
          'end-date-before-start-date': 'The end date must be after the start date',
          'subject-too-long': 'The subject is too long. Maximum 60 characters.',
          'description-too-long': 'The description is too long. Maximum 1200 characters.',
          'missing-date': 'The date or time is missing',
          'missing-description': 'The description is missing.',
          'missing-effect': 'The impact is missing.',
          'missing-cause': 'The cause is missing.',
          'invalid-agency':
            'The agency indicated does not exist or you do not have permission to import alerts for the agency indicated',
          'invalid-date': 'The date or time format is not recognized.',
          'invalid-route': 'A line does not exist in the selected Agency',
          'agency-not-selected': 'The agency is not included in the import impacted agencies',
          'missing-agency-id': 'The Agency is missing',
          'missing-subject': 'The subject is missing',
          'missing-routes': 'Line/s missing',
          'invalid-cause': 'The cause does not exist',
          'invalid-effect': 'The impact does not exist',
        },
        'problems-detected':
          '{count} {count, plural, one {problem has} other {problems have}} been detected. Unless you correct the problems on this page, these alerts will not be imported',
      },
      'select-agencies': {
        label: 'Select agencies affected by the import',
      },
      'select-file': {
        button: 'Select file',
        'instructions-0': 'Import alerts :',
        'instructions-1': '<b>Step 1:</b> Select the Agencies to be updated by the Import',
        'instructions-2': '<b>Step 2:</b> Upload an xls file and correct any problems detected',
        'instructions-3': '<b>Step 3:</b> Confirm the import',
      },
      upload: {
        button: 'Start import',
      },
    },
    'alert-update': {
      title: ' تحديث التنبيه ',
      'access-button': ' تحديث التنبيه ',
      'restart-button': 'Restart alert',
      'close-button': 'Close alert',
      broadcastLater: 'Program broadcast',
      broadcast: 'Broadcast now',
      'file-import': ' - File import',
      'start-date': {
        error: {
          required: 'Please specify a start date for the disruption period',
        },
      },
      'end-date': {
        error: {
          incomplete: ' المرجو إكمال تاريخ الانتهاء ',
          beforeStartDate: ' المرجو تحديد تاريخ بعد تاريخ البدء ',
          beforeCurrentDate: 'Please specify a date later than the current date',
          beforeBroadcastDate: 'Please specify a broadcast date before the end date',
        },
      },
      'close-modal': {
        error: 'There was an error closing the alert',
        confirm: 'Confirm',
        cancel: 'Cancel',
        question:
          "Are you sure you want to close the alert? It won't visible anymore on the website, the mobile application and Google Maps",
      },
      information: ' معلومات التنبيه ',
      routes: ' الخطوط المتأثرة ',
      channels: ' قنوات التواصل ',
      summary: ' التاشير ',
      'confirm-broadcast-question': ' هل تؤكد نشر هذا التحديث؟ ',
      'confirm-broadcast': ' نعم ',
      'cancel-broadcast': ' لا ',
      error: {
        INVALID_ROUTE_ID: 'أحد الخطوط المشار إليها غير موجود أو لم يعد موجودًا في الشبكة',
        TWITTER_MESSAGE_TOO_LONG: ' يجب أن تحتوي رسالة تويتر على أقل من 280 حرفًا ',
      },
    },
    'alert-list': {
      create: ' إنشاء تنبيه جديد ',
      createTemplate: 'Alert templates',
      'empty-list': ' لا يوجد تنبيه ',
      importAlerts: 'تنبيهات الاستيراد',
      filters: {
        future: 'Programmed alerts',
        'on-going': ' التنبيهات قيد التقدم ',
        completed: ' التنبيهات السابقة ',
        draft: ' المسودات ',
      },
      'delete-draft': {
        confirm: 'Confirm',
        cancel: 'Cancel',
        'question-draft': 'Are you sure you want to delete the draft?',
        question: 'Are you sure you want to delete the alert?',
      },
      agencies: {
        label: 'Agency',
      },
      routes: {
        label: ' خط ',
        network: ' الشبكة بأكملها ',
        type: '{type} الشبكة ',
      },
      title: ' جدول التنبيهات ',
      number: 'رقم',
      'last-broadcast': ' البث السابق ',
      'broadcast-status': ' وضعية الإرسال ',
      actions: ' اجراءات ',
      'insufficient-permissions':
        'You cannot update this alert because it also includes lines in Agencies for which you do not have permission',
      preview: {
        seeMore: 'شاهد المزيد',
      },
    },
    'alert-template-list': {
      title: 'Alert templates',
      name: 'Name',
      creationDate: 'Creation date',
      modificationDate: 'Last modified',
      createdBy: 'Created by',
      actions: 'Actions',
      create: 'Create a new alert template',
      'copy-modal': {
        error: 'There was an error duplicating the alert template',
        confirm: 'Confirm',
        cancel: 'Cancel',
        question: 'Do you want to duplicate the alert template?',
      },
      'delete-modal': {
        error: 'There was an error deleting the alert template',
        confirm: 'Confirm',
        cancel: 'Cancel',
        question: 'Are you sure you want to delete the alert template?',
      },
    },
    'alert-template-creation': {
      title: 'Alert template creation',
      submit: 'Save template',
      error: {
        EMPTY_NAME: 'Please enter a name for your template',
        INVALID_NAME: 'A template with this name already exists',
        DEFAULT_ERROR: 'There was an error creating your template',
      },
    },
    'alert-template-edition': {
      title: 'Template',
      submit: 'Save changes',
      'use-button': 'Create an alert from this template',
      error: {
        EMPTY_NAME: 'Please enter a name for your template',
        INVALID_NAME: 'A template with this name already exists',
        DEFAULT_ERROR: 'There was an error updating your template',
      },
    },
    'network-creation': {
      title: ' معلومات الشبكة ',
      network: {
        label: ' اسم الشبكة ',
        placeholder: ' شبكة الاتصال ',
        error: {
          required: ' مرجو تحديد الشبكة ',
        },
      },
      submit: ' إنشاء الشبكة ',
    },
    'network-list': {
      create: ' إنشاء شبكة جديدة ',
      title: ' شبكاتي ',
      name: ' شبكة الاتصال ',
      creationDate: ' تاريخ الإنشاء ',
    },
    settings: {
      title: ' معدات الشبكة ',
      users: {
        title: ' إدارة المستخدمين ',
        'empty-list': ' لا يوجد مستخدم ',
        'first-name': ' الاسم الشخصي ',
        'last-name': ' الاسم العائلي ',
        email: ' البريد الإلكتروني ',
        role: ' وظيفة ',
        actions: ' أجراءات ',
        superadmin: ' مشرف متميز ',
        admin: ' مشرف ',
        operator: ' مشغل ',
        agencies: 'Agencies',
        'all-agencies': ' الشبكة بأكملها ',
      },
      update: {
        'modal-title': 'Update a user',
        'ok-text': 'Update',
      },
      invitations: {
        title: ' الدعوات المعلقة ',
        create: ' دعوة مستخدم ',
        'empty-list': ' لا يوجد ضيف ',
        email: ' البريد الإلكتروني ',
        role: ' وظيفة ',
        actions: ' اجراءات ',
      },
      invite: {
        'modal-title': ' دعوة مستخدم جديد ',
        ok: ' دعوة ',
      },
      error: {
        'agency-required': 'At least 1 agency must be selected',
        required: ' البريد الالكتروني مطلوب ',
        'invalid-email': ' البريد الإلكتروني المقدم غير صالح ',
        ALREADY_EXISTS: ' المستخدم جزء من الشبكة ',
        ALREADY_INVITED: ' تمت دعوة المستخدم إلى هذه الشبكة ',
        IS_SUPERADMIN: ' المستخدم هو مشرف متميز، يمكنه الوصول إلى هذه الشبكة ',
      },
    },
  },
  utils: {
    date: {
      today: ' اليوم ',
      tomorrow: ' غدا ',
      yesterday: ' أمس ',
      format: 'DD/MM/YYYY',
      placeholder: 'JJ/MM/AAAA',
    },
    time: {
      format: 'HH:mm',
      placeholder: 'HH:MM',
    },
  },
};
export default arTranslations;
