import styled from 'styled-components';

export default styled.div`
  width: 100%;
  height: 100%;

  .react-svg {
    width: 100%;
    height: 100%;
  }

  .react-svg > div {
    width: 100%;
    height: 100%;
  }
`;
