import { parse } from 'query-string';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useAsyncFn } from 'react-use';

import { Paths } from 'routes';
import client from 'services/networking/client';

export const useLogin = () => {
  const navigate = useNavigate();
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  return useAsyncFn(async (payload) => {
    const token: string | undefined = await client.login(payload);
    if (token) {
      navigate(Paths.NETWORK_LIST);
      // We do that for the Websocket connection at app root to access the fresh token
      // eslint-disable-next-line no-restricted-globals
      console.warn(
        'Reloading page from Callback.tsx to get the fresh token from the local storage.',
      );
      location.reload();
    } else {
      throw new Error('No token in login response body');
    }
  });
};

const Callback: React.FC = () => {
  const location = useLocation();
  const queryParams = parse(location.search);

  const [hasTriedLogin, setHasTriedLogin] = useState(false);
  const [{ loading, error }, login] = useLogin();

  if (!loading && !hasTriedLogin) {
    login(queryParams);
    setHasTriedLogin(true);
  }

  return loading ? (
    <div>Loading...</div>
  ) : error ? (
    <div>Error: {error.message}</div>
  ) : (
    <div>Sucess you will be redirected</div>
  );
};

export default Callback;
