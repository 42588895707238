import { useParams as useReactRouterParams } from 'react-router';

export const useRequiredParams = <T extends string>(
  paramNames: Array<T>,
): Readonly<Record<T, string>> => {
  const params = useReactRouterParams();
  paramNames.forEach((paramName: T) => {
    if (params[paramName] === undefined) {
      throw new Error(`Missing parameters, available parameters : ${params}`);
    }
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  return params;
};
