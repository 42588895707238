import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReconnectingWebSocket from 'reconnecting-websocket';

import { createWebsocketConnection } from 'services/feedback';
import client from 'services/networking/client';

const WsConfig: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const [tokenUsedForWS, setTokenUsedForWS] = useState<string>();
  const [websocketConnection, setWebsocketConnection] = useState<ReconnectingWebSocket | null>();

  useEffect(() => {
    const token = client.getToken();
    if (!token) {
      websocketConnection?.close();
      setWebsocketConnection(null);
    }

    if (token && !websocketConnection) {
      setWebsocketConnection(createWebsocketConnection(token));
      setTokenUsedForWS(token);
    }

    // Closing existing websocket connection if token has been refreshed
    if (token && websocketConnection && token !== tokenUsedForWS) {
      websocketConnection.close();
      setWebsocketConnection(null);
    }

    return () => {
      if (!token) {
        websocketConnection?.close();
      }
    };
  }, [location, websocketConnection, setWebsocketConnection, tokenUsedForWS, setTokenUsedForWS]);

  return <>{children}</>;
};

export default WsConfig;
