import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { unregister } from './serviceWorker';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN ?? undefined,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT ?? 'dev',
  integrations: [Sentry.replayIntegration()],
  // Session Replay
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1,
});

// https://vitejs.dev/guide/build#load-error-handling
// This happens after a deployment. The old bundle no longer exists, but it is still mentioned in the html
window.addEventListener('vite:preloadError', (event) => {
  console.warn('Caught preload error.', JSON.stringify(event));
});

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(<App />);
  unregister();
}
