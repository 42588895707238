import { useEffect, useState } from 'react';

import { Role, roleHierarchy } from 'types/user';

import client from './client';

const useRole = (): Role | undefined => {
  const [role, setRole] = useState(client.getUserRole());
  useEffect(() => {
    const onRoleUpdate = (event: any) => {
      setRole(event.role);
    };

    document.addEventListener('roleUpdate', onRoleUpdate);
    return () => {
      document.removeEventListener('roleUpdate', onRoleUpdate);
    };
  }, []);

  return role;
};

export const isUnder = (
  roleA: Role,
  roleB: Role,
  { strict }: { strict: boolean } = { strict: false },
) => {
  return strict
    ? roleHierarchy.findIndex((role) => role === roleA) <
        roleHierarchy.findIndex((role) => role === roleB)
    : roleHierarchy.findIndex((role) => role === roleA) <=
        roleHierarchy.findIndex((role) => role === roleB);
};

export const checkUserRole = (userRole: Role | undefined, authorizedRole: Role): boolean => {
  if (!userRole || !Object.values(Role).includes(userRole)) {
    return false;
  }

  return isUnder(authorizedRole, userRole);
};

export default useRole;
