import React from 'react';
import { ReactSVG } from 'react-svg';

import Container from './SVG.style';

interface SVGProps {
  src: string;
}

const SVG: React.FunctionComponent<SVGProps> = ({ src }) => (
  <Container>
    <ReactSVG className="react-svg" src={src} />
  </Container>
);

export default SVG;
