import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useAsync } from 'react-use';

import ScrollToTop from 'components/ScrollToTop';
import StyleAndIntlConfig from 'components/StyleAndIntlConfig';
import WsConfig from 'components/WsConfig';
import { FeatureFlagsProvider } from 'services/featureFlags';
import client from 'services/networking/client';

import AppCrashFallback from './components/AppCrashFallback';
import ErrorBoundary from './components/ErrorBoundary';
import Root from './components/Root';
import Routes from './routes';

const App: React.FunctionComponent = () => {
  useAsync(async () => {
    await client.checkToken();
  });

  return (
    <FeatureFlagsProvider>
      <ErrorBoundary FallbackComponent={AppCrashFallback}>
        <StyleAndIntlConfig>
          <BrowserRouter>
            <ScrollToTop />
            <Root>
              <WsConfig>
                <Routes />
              </WsConfig>
            </Root>
          </BrowserRouter>
        </StyleAndIntlConfig>
      </ErrorBoundary>
    </FeatureFlagsProvider>
  );
};

export default App;
