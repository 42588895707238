import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import styled from 'styled-components';

import Callback from './components/Callback';
import Loader from './components/Loader/Loader';
import { NetworkFeaturesProvider } from './components/NetworkFeaturesProvider/NetworkFeaturesProvider';

const AlertCreation = lazy(() => import('./pages/AlertCreation'));
const AlertImport = lazy(() => import('./pages/AlertImport'));
const AlertList = lazy(() => import('./pages/AlertList'));
const AlertDetail = lazy(() => import('./pages/AlertDetail'));
const AlertAdmin = lazy(() => import('./pages/AlertAdmin'));
const AlertUpdate = lazy(() => import('./pages/AlertUpdate'));
const AlertTemplateList = lazy(() => import('./pages/AlertTemplateList'));
const AlertTemplateCreation = lazy(() => import('./pages/AlertTemplateCreation'));
const AlertTemplateEdition = lazy(() => import('./pages/AlertTemplateEdition'));
const NetworkCreation = lazy(() => import('./pages/NetworkCreation'));
const NetworkList = lazy(() => import('./pages/NetworkList'));
const Settings = lazy(() => import('./pages/Settings'));

export enum Paths {
  ALERT_CREATION = '/networks/:networkName/alerts/new',
  ALERT_INFORMATION_EDITION = '/networks/:networkName/alerts/:alertId/information',
  ALERT_ROUTES_SELECTION = '/networks/:networkName/alerts/:alertId/routes',
  ALERT_CHANNELS_CONFIGURATION = '/networks/:networkName/alerts/:alertId/channels',
  ALERT_CREATION_SUMMARY = '/networks/:networkName/alerts/:alertId/summary',
  ALERT_ADMIN = '/networks/:networkName/alerts/:alertId/admin',
  ALERT_DETAIL = '/networks/:networkName/alerts/:alertId/detail',
  ALERT_IMPORT = '/networks/:networkName/alerts/import',
  ALERT_UPDATE = '/networks/:networkName/alerts/:alertId/update',
  ALERT_TEMPLATE_LIST = '/networks/:networkName/alerts/templates',
  ALERT_TEMPLATE_CREATION = '/networks/:networkName/alerts/templates/new',
  ALERT_TEMPLATE_EDITION = '/networks/:networkName/alerts/templates/:alertTemplateId/update',
  ALERT_LIST = '/networks/:networkName/alerts',
  NETWORK_CREATION = '/networks/new',
  NETWORK_LIST = '/networks',
  AUTHENTICATION_CALLBACK = '/callback',
  SETTINGS = '/networks/:networkName/settings',
}

const AntdStyleOverride = styled.div`
  .ant-legacy-form-item-label {
    line-height: 40px;
  }

  .ant-row.ant-row-rtl .ant-legacy-form-item-label {
    flex-basis: content;
  }

  .ant-legacy-form-vertical .ant-legacy-form-item-label label::after,
  .ant-col-24.ant-legacy-form-item-label label::after,
  .ant-col-xl-24.ant-legacy-form-item-label label::after {
    display: inline-block;
  }

  .ant-legacy-form-vertical .ant-row-rtl .ant-legacy-form-item-label {
    text-align: inherit;
  }

  .ant-checkbox {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', 'tnum';
    position: relative;
    top: -0.09em;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    vertical-align: middle;
    outline: none;
    cursor: pointer;
    align-self: unset;
    transform: unset;
  }
`;

const routes = () => (
  <Suspense fallback={<Loader />}>
    <AntdStyleOverride>
      <Routes>
        <Route path={Paths.ALERT_CREATION} element={<AlertCreation step={1} />} />
        <Route path={Paths.ALERT_INFORMATION_EDITION} element={<AlertCreation step={1} />} />
        <Route
          path={Paths.ALERT_ROUTES_SELECTION}
          element={
            <NetworkFeaturesProvider>
              <AlertCreation step={2} />
            </NetworkFeaturesProvider>
          }
        />
        <Route
          path={Paths.ALERT_CHANNELS_CONFIGURATION}
          element={
            <NetworkFeaturesProvider>
              <AlertCreation step={3} />
            </NetworkFeaturesProvider>
          }
        />
        <Route
          path={Paths.ALERT_CREATION_SUMMARY}
          element={
            <NetworkFeaturesProvider>
              <AlertCreation step={4} />
            </NetworkFeaturesProvider>
          }
        />

        <Route
          path={Paths.ALERT_IMPORT}
          element={
            <NetworkFeaturesProvider>
              <AlertImport />
            </NetworkFeaturesProvider>
          }
        />

        <Route
          path={Paths.ALERT_LIST}
          element={
            <NetworkFeaturesProvider>
              <AlertList />
            </NetworkFeaturesProvider>
          }
        />
        <Route path={Paths.ALERT_TEMPLATE_LIST} element={<AlertTemplateList />} />
        <Route path={Paths.ALERT_TEMPLATE_CREATION} element={<AlertTemplateCreation />} />
        <Route path={Paths.ALERT_TEMPLATE_EDITION} element={<AlertTemplateEdition />} />
        <Route path={Paths.ALERT_ADMIN} element={<AlertAdmin />} />
        <Route
          path={Paths.ALERT_DETAIL}
          element={
            <NetworkFeaturesProvider>
              <AlertDetail />
            </NetworkFeaturesProvider>
          }
        />
        <Route
          path={Paths.ALERT_UPDATE}
          element={
            <NetworkFeaturesProvider>
              <AlertUpdate />
            </NetworkFeaturesProvider>
          }
        />
        <Route path={Paths.NETWORK_CREATION} element={<NetworkCreation />} />
        <Route path={Paths.NETWORK_LIST} element={<NetworkList />} />
        <Route
          path={Paths.SETTINGS}
          element={
            <NetworkFeaturesProvider>
              <Settings />
            </NetworkFeaturesProvider>
          }
        />
        <Route path={Paths.AUTHENTICATION_CALLBACK} element={<Callback />} />
        <Route path="/" element={<Navigate to={Paths.NETWORK_LIST} />} />
        <Route element={<Navigate to="/" />} />
      </Routes>
    </AntdStyleOverride>
  </Suspense>
);

export default routes;
