import keys from 'lodash/keys';
import { createIntl, createIntlCache } from 'react-intl';

import ar from './ar';
import en from './en';
import fr from './fr';
import it from './it';

type Message = string | NestedDictionary;
interface NestedDictionary {
  [x: string]: Message;
}
interface FlattenedDictionary {
  [x: string]: string;
}

export const flattenMessages = (
  nestedMessages: NestedDictionary,
  prefix = '',
): FlattenedDictionary =>
  keys(nestedMessages).reduce((messages: FlattenedDictionary, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});

const translations: Record<string, FlattenedDictionary> = {
  en: flattenMessages(en),
  fr: flattenMessages(fr),
  it: flattenMessages(it),
  ar: flattenMessages(ar),
};

export const NAVIGATOR_LOCALE = window.navigator.language;
export const NAVIGATOR_LANGUAGE = NAVIGATOR_LOCALE.split('-')[0];
export const cache = createIntlCache();
export const intl = createIntl(
  {
    locale: NAVIGATOR_LOCALE,
    messages:
      NAVIGATOR_LANGUAGE in translations ? translations[NAVIGATOR_LANGUAGE] : translations.en,
  },
  cache,
);

export const effectEntries = [
  ...Object.entries(en.alert.effect),
  ...Object.entries(fr.alert.effect),
  ...Object.entries(it.alert.effect),
  ...Object.entries(ar.alert.effect),
];

export const causeEntries = [
  ...Object.entries(en.alert.cause),
  ...Object.entries(fr.alert.cause),
  ...Object.entries(it.alert.cause),
  ...Object.entries(ar.alert.cause),
];
