import * as Sentry from '@sentry/react';

export const captureErrorWithSentry = (
  error: unknown,
  { endpoint, method }: { endpoint: string; method: string },
): void => {
  const errorForSentry = error as {
    status?: number;
    message?: string;
    response?: { text?: string };
  };
  Sentry.withScope((scope) => {
    // Do not send 401 errors to Sentry
    if (errorForSentry.status === 401) {
      return;
    }

    const UUID_REGEX = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g;
    const NETWORK_REGEX = /networks\/[0-9a-z-]+\//g;
    const endpointFingerprint = endpoint
      .replaceAll(NETWORK_REGEX, 'networks/#/')
      .replaceAll(UUID_REGEX, '*')
      .replaceAll(/\d+/g, '*');

    const statusCode = errorForSentry.status?.toString() ?? '';
    const errorMessage = (errorForSentry.response?.text || errorForSentry.message) ?? '';

    // Group errors together based on the request and error response
    scope.setFingerprint([method, endpointFingerprint, statusCode, errorMessage]);

    // Setting custom error message for Sentry
    errorForSentry.message = `${statusCode} ${method} ${endpointFingerprint} ${errorForSentry.message}`;
    Sentry.captureException(errorForSentry, {
      tags: {
        from: 'request',
        endpointFingerprint,
        statusCode,
        request: `${method} ${endpoint}`,
      },
      extra: { message: errorMessage },
    });
  });
};
