/* eslint-disable max-lines */
import { BroadcastChannel } from 'types/broadcastChannels';

import { Translations } from './types';

const frTranslations: Translations = {
  languages: {
    fr: 'Français',
    en: 'Anglais',
    it: 'Italien',
    br: 'Breton',
    ar: 'Arabe',
  },
  alert: {
    number: 'Numéro d’alerte',
    'creation-date': 'Date de création',
    'created-by': 'Créateur de l’alerte',
    cause: {
      label: 'Cause',
      ACCIDENT: 'ACCIDENT',
      TECHNICAL_PROBLEM: 'INCIDENT TECHNIQUE',
      MAINTENANCE: 'MAINTENANCE',
      CONSTRUCTION: 'TRAVAUX',
      STRIKE: 'GRÈVE',
      DEMONSTRATION: 'MANIFESTATION',
      WEATHER: 'MÉTÉO',
      HOLIDAY: 'VACANCES',
      POLICE_ACTIVITY: 'ACTIVITÉ POLICIÈRE',
      MEDICAL_EMERGENCY: 'URGENCE MÉDICALE',
      OTHER_CAUSE: 'AUTRE CAUSE',
      UNKNOWN_CAUSE: 'CAUSE INCONNUE',
    },
    effect: {
      label: 'Impact',
      NO_SERVICE: 'AUCUN SERVICE',
      SIGNIFICANT_DELAYS: 'RETARDS SIGNIFICATIFS',
      DETOUR: 'DÉVIATION',
      REDUCED_SERVICE: 'SERVICE RÉDUIT',
      STOP_MOVED: 'ARRÊT DÉPLACÉ',
      MODIFIED_SERVICE: 'SERVICE MODIFIÉ',
      ADDITIONAL_SERVICE: 'SERVICE ADDITIONNEL',
      OTHER_EFFECT: 'AUTRE EFFET',
      UNKNOWN_EFFECT: 'EFFET INCONNU',
    },
    'import-type': {
      new: 'nouveau',
      update: 'mise à jour',
      closure: 'clôture',
      no_change: 'aucun changement',
      problem: 'problème',
      processing: 'en traitement',
      ready_to_import: 'ok',
    },
    'start-date': 'Début de perturbation',
    'end-date': 'Fin de perturbation',
    'route-type': {
      '0': 'Tramway',
      '1': 'Métro',
      '2': 'Train',
      '3': 'Bus',
      '4': 'Ferry',
      '5': 'Tramway à traction par câble',
      '6': 'Télécabine ou téléphérique',
      '7': 'Funiculaire',
      '100': 'Service de chemin de fer',
      '101': 'Service de chemin de fer à grande vitesse',
      '102': 'Grandes lignes de chemin de fer',
      '103': 'Service de chemin de fer transrégional',
      '104': 'Service de transport de voitures par chemin de fer',
      '105': 'Service de chemin de fer à couchettes',
      '106': 'Service de chemin de fer régional',
      '107': 'Service de chemin de fer touristique',
      '108': "Navette ferroviaire (au sein d'un complexe)",
      '109': 'Chemin de fer de banlieue',
      '110': 'Service de chemin de fer de substitution',
      '111': 'Service de chemin de fer spécial',
      '112': 'Service de transport de camions par chemin de fer',
      '113': 'Service de chemin de fer (toute catégorie)',
      '114': 'Service de chemin de fer transnational',
      '115': 'Service de transport de véhicules par chemin de fer',
      '116': 'Chemin de fer à crémaillère',
      '117': 'Service de chemin de fer supplémentaire',
      '200': 'Service de cars',
      '201': 'Service de cars internationaux',
      '202': 'Service de cars nationaux',
      '203': 'Service de navette par cars',
      '204': 'Service de cars régionaux',
      '205': 'Service de cars spéciaux',
      '206': 'Service de visite touristique en car',
      '207': 'Service de cars touristiques',
      '208': 'Service de cars de banlieue',
      '209': 'Service de cars (toute catégorie)',
      '400': 'Service de chemin de fer urbain',
      '401': 'Service de chemin de fer métropolitain',
      '402': 'Service de transport souterrain',
      '403': 'Service de chemin de fer urbain',
      '404': 'Service de chemin de fer urbain (toute catégorie)',
      '405': 'Monorail',
      '700': "Service d'autobus",
      '701': "Service d'autobus régional",
      '702': "Service d'autobus express",
      '703': "Service d'autobus desservant des arrêts",
      '704': "Service d'autobus local",
      '705': "Service d'autobus nocturne",
      '706': 'Service de car postal',
      '707': "Service d'autobus pour passagers présentant des besoins spéciaux",
      '708': "Service d'autobus pour personnes à mobilité réduite",
      '709': "Service d'autobus pour personnes à mobilité réduite (déclarées comme telles)",
      '710': 'Autobus panoramique',
      '711': 'Service de navette par autobus',
      '712': 'Bus scolaire',
      '713': 'Bus scolaire et à usage public',
      '714': "Service de substitution par autobus (remplacement d'un service de chemin de fer)",
      '715': 'Autobus à la demande',
      '716': "Service d'autobus (toute catégorie)",
      '717': 'Service de taxi partagé',
      '800': 'Service de trolleybus',
      '900': 'Service de tramway',
      '901': 'Service de tramway urbain',
      '902': 'Service de tramway local',
      '903': 'Service de tramway régional',
      '904': 'Service de tramway touristique',
      '905': 'Service de navette par tramway',
      '906': 'Service de tramway (toute catégorie)',
      '907': 'Tramway à traction par câble',
      '1000': 'Service de transport fluvial',
      '1100': 'Service de transport aérien',
      '1200': 'Service de ferry',
      '1300': 'Service de téléphérique',
      '1400': 'Service de funiculaire',
      '1500': 'Service de taxis',
      '1501': 'Service de taxis communaux',
      '1502': 'Service de taxi fluvial',
      '1503': 'Service de taxis en gare de chemin de fer',
      '1504': 'Service de vélotaxi',
      '1505': 'Service de taxis réglementé',
      '1506': 'Service de location de véhicules particuliers',
      '1507': 'Service de taxis (toute catégorie)',
      '1700': 'Services divers',
    },
    channel: {
      EMAIL: 'Campagne Email',
      SMS: 'Campagne SMS',
      TRANSACTIONAL_SMS: 'SMS',
      TRANSACTIONAL_EMAIL: 'Email',
      GTFS_RT: 'Site web + App + Google Maps',
      TWITTER: 'X/Twitter',
      PUSH: 'Push notification',
      WEBSITE_BANNER: 'Bannière site web',
    },
    channelContact: {
      TRANSACTIONAL_SMS: 'sms',
      TRANSACTIONAL_SMS_PLURAL: 'sms',
      TRANSACTIONAL_EMAIL: 'email',
      TRANSACTIONAL_EMAIL_PLURAL: 'emails',
    },
    broadcastStatus: {
      DRAFT: 'Brouillon',
      ON_GOING: 'En cours',
      PROGRAMMED: 'À venir',
      SENT: 'Envoyé',
      SKIPPED: 'Ignoré',
      ERROR: 'Problème',
      CANCELED: 'Annulé',
    },
    broadcastStatusTooltip: {
      SKIPPED: {
        TWITTER:
          'La diffusion sur Twitter n’a pas eu lieu car le contenu sur ce canal est identique à la diffusion précédente',
        TRANSACTIONAL_SMS: "La diffusion sur SMS n'a pas eu lieu car il n'y a aucun abonné",
        TRANSACTIONAL_EMAIL: "La diffusion sur Email n'a pas eu lieu car il n'y a aucun abonné",
      },
    },
  },
  component: {
    header: {
      title: 'DAIT',
    },
    'alert-information-overview': {
      title: "Informations sur l'alerte",
    },
    'alert-routes-overview': {
      title: 'Lignes impactées',
    },
    'routes-selection': {
      'select-all': 'Tout sélectionner',
      title: 'Lignes',
      error: {
        required: 'Veuillez renseigner au moins une ligne',
      },
      'see-routes': 'Voir les lignes',
      'select-routes': '{routeType} - Sélectionnez les lignes concernées',
      validate: 'Valider',
      'route-types': 'Modes de transport',
    },
    'alert-routes-card': {
      'no-route-selected': 'Aucune ligne sélectionnée',
    },
    'alert-template-form': {
      'template-name': {
        title: 'Nom du modèle',
        name: {
          label: 'Nom',
          error: {
            EMPTY_NAME: 'Veuillez entrer un nom',
            INVALID_NAME: 'Veuillez modifier le nom',
          },
        },
      },
      'alert-information': {
        title: "Informations sur l'alerte",
        cause: {
          placeholder: 'Cause',
          label: 'Cause',
        },
        effect: {
          placeholder: 'Impact',
          label: 'Impact',
        },
      },
      channels: {
        title: 'Canaux de communication',
      },
    },
    'channels-configuration': {
      'delete-language': 'Supprimer',
      'confirm-deletion': 'Confirmer',
      'cancel-deletion': 'Annuler',
      'confirm-deletion-text': 'Êtes-vous sûr de vouloir supprimer cette langue ?',
      'alert-content': {
        'title-first': 'Contenu de l’alerte',
        'title-other': 'Contenu de l’alerte (langue {index})',
        'translation-label': 'Générer automatiquement une traduction',
        'translation-warning':
          'Attention! Les traductions automatiques peuvent parfois contenir des erreurs. Il est donc important de vérifier le contenu avant la diffusion de votre alerte.',
        'translation-error':
          'Le service de traduction automatique est indisponible. Nous vous invitons à réessayer ultérieurement ou vous tourner vers les solutions de traductions alternatives.',
        'translation-truncated':
          'La traduction proposée dépasse le nombre de caractères autorisés. Il est donc important de vérifier le contenu avant la diffusion de votre alerte.',
      },
      enrich: 'Canal {channel} - Enrichir',
      'add-language': 'Ajouter une nouvelle langue de diffusion',
      save: 'Enregistrer',
      language: {
        label: 'Langue de diffusion',
        error: {
          required: 'Veuillez renseigner une langue de diffusion pour cette alerte',
        },
      },
      object: {
        label: 'Objet :',
        help: "Ce champs sera utilisé comme objet de l'email, titre de la notification push, et titre de l’alerte  diffusée sur le site web, l’application mobile et le flux GTFS-RT.",
        error: {
          required: 'Veuillez renseigner un objet pour cette alerte',
          'max-length': 'Veuillez renseigner un objet de moins de 60 caractères',
        },
      },
      [BroadcastChannel.WEBSITE_BANNER]: {
        label: 'Contenu Bannière site web ({language})',
        note: 'Configurer et créer une bannière sur votre site web.',
        bannerTitle: {
          label: 'Titre du bandeau',
          description: "Message visible sur le bandeau d'information",
        },
        pageTitle: {
          label: 'Titre',
          description: "Titre de la page associée au bandeau d'information",
        },
        bannerContent: {
          label: 'Description',
          description: "Contenu de la page associée au bandeau d'information",
        },
      },
      [BroadcastChannel.TRANSACTIONAL_EMAIL]: {
        label: "Contenu de l'e-mail ({language})",
        note: "Modifiez le contenu et créez un e-mail de test, recevez votre alerte telle qu'elle sera vue par vos utilisateurs.",
        test: {
          error: 'Echec !',
          errorDetails: "L'e-mail n'a pas été envoyé. Veuillez réessayer.",
          sending: 'Envoi en cours...',
          sendTest: 'Recevoir un email test',
          sent: 'Envoyé !',
          sentDetails: 'Votre email test est disponible dans votre boite mail',
        },
      },
      'short-description': {
        label: 'Description courte (SMS, Push notifications) :',
        error: {
          required: 'Veuillez saisir le contenu de votre alerte',
          'max-length': 'Veuillez saisir une description courte de moins de 152 caractères',
        },
      },
      'long-description': {
        label:
          'Description longue (Site web + application + Google Maps, E-mail, Twitter, Page bannière site web) :',
        error: {
          required: 'Veuillez saisir le contenu de votre alerte',
          'max-length': 'Veuillez saisir une description longue de moins de 1200 caractères',
        },
        note: 'Vous pouvez mettre en forme le contenu de votre alerte sur le canal email. Pour cela, sélectionnez le canal email dans les canaux de communication ci-dessous et cliquez sur l’icône d’édition sur la droite du tableau',
      },
      'twitter-enrich-subtext':
        'Enrichissez vos publications Twitter en créant des posts connectés pour la mise à jour de l’alerte ou en ajoutant des images à votre alerte.',
      'twitter-history-title': 'Historique des tweets',
      'twitter-history-empty': 'Aucun tweet publié pour le moment. Créer un nouveau tweet.',
      'twitter-description': {
        label: 'Nouveau tweet',
        error: {
          required: 'Veuillez renseigner le contenu pour cette alerte',
          'max-length': 'Veuillez renseigner une description de moins de 280 caractères',
        },
      },
      'twitter-image-description': {
        label: 'Ajouter une description',
        note: "Améliorez l'accessibilité et la visibilité de votre post en ajoutant une description à votre image !",
      },
      'rich-text': {
        'link-title': 'Insérer un lien',
        'link-name': 'Texte à afficher',
        'link-target': 'Lien',
        insert: 'Insérer',
      },
      upload: {
        title: 'Ajouter un fichier ou un lien',
        label: 'Ajoutez un fichier PDF',
        button: 'Charger un document',
        copyInfo: "L'URL est bien copiée dans votre presse-papier",
        copyError: "Le copier-coller n'a pas fonctionné",
        tip1: 'Si vous téléchargez un document, celui-ci sera automatiquement inséré dans le canal "Site web + App + Google Maps".',
        tip2: "Si vous souhaitez le joindre à d'autres canaux, copiez-collez l'URL du plan dans le contenu des canaux de votre choix.",
        linkLabel: 'Ajoutez un lien URL',
        linkPlaceholder: 'https://example.com',
        linkTip:
          'Ce lien sera ajouté dans le contenu de l’alerte envoyée dans les différents médias.',
        tooltip:
          "Intégrez un fichier ou une URL à votre alerte. Cet élément sera disponible dans l'ensemble de vos médias. Vous pouvez uniquement intégrer un lien ou un fichier.",
        'invalid-url': 'Merci de saisir un URL valide',
      },
      uploadImage: {
        button: 'Cliquez pour ajouter un fichier',
        label: 'Ajouter une image',
        supportedFiles: 'Fichiers supportés: JPEG, JPG, PNG',
        size: 'Les images sont affichées au ratio 16:9, ou à la taille 600px par 335px, dans le flux de Twitter. Et lorsqu’elles sont cliquées, elles sont agrandies jusqu’à 1 200 px par 675px.',
        sizeError: 'Le fichier téléchargé dépasse la taille maximale autorisée (5 MB).',
      },
      channels: {
        label: 'Gérer les canaux de communication',
        error: {
          required: 'Veuillez sélectionner au moins un canal de diffusion',
        },
        'twitter-multilingual-error':
          "Le message ne peut être diffusé sur twitter qu'en {defaultLanguage}",
        'push-error':
          'Pour diffuser votre alerte sur le canal "Push Notification", le canal "Site web + App + Google Maps" doit être sélectionné.',
        empty: 'Aucun canal disponible',
        enrich: 'Enrichir',
        info: 'Dans cette section vous aurez la possibilité de gérer et compléter les contenus de l\'ensemble des canaux de communication avant la diffusion de votre alerte. Cliquez sur "Enrichir" pour gérer le contenu de chaque canal !',
        name: 'Canal',
        message: 'Message',
        'message-type': {
          SHORT: 'Court',
          LONG: 'Long',
          CUSTOM: 'Personnalisé',
        },
        update: 'Actions',
      },
    },
    'broadcast-date-configuration': {
      'confirm-programmation': 'Confirmer',
      'cancel-programmation': 'Annuler',
      title: "Programmer la diffusion de l'alerte",
      'broadcast-before-today':
        'Veuillez sélectionner une date et une heure de diffusion ultérieures à la date actuelle',
      'broadcast-after-end':
        'Veuillez programmer votre alerte à une date antérieure à la date de fin de perturbation',
    },
  },
  page: {
    'alert-creation': {
      'template-selection': {
        'use-template': 'Utiliser un modèle',
        confirm: 'Confirmer',
        content:
          'Les étapes 1 et 3 seront préremplies avec les informations présentes dans le modèle',
        select: 'Sélectionnez un modèle',
        cancel: 'Annuler',
        errorLabel: 'Veuillez sélectionner un modèle',
      },
      steps: {
        information: "Informations sur l'alerte",
        routes: 'Lignes impactées',
        channels: 'Canaux de communication',
        summary: 'Validation',
      },
      information: {
        error: {
          ALERT_ALREADY_PUBLISHED: "L'alerte a déjà été diffusée",
        },
      },
      'routes-selection': {
        agency: 'Agences : ',
        error: {
          ALERT_ALREADY_PUBLISHED: "L'alerte a déjà été diffusée",
        },
      },
      'channels-configuration': {
        error: {
          ALERT_ALREADY_PUBLISHED: "L'alerte a déjà été diffusée",
          TWITTER_MESSAGE_TOO_LONG: 'Le message Twitter doit faire moins de 280 caractères',
        },
      },
      cause: {
        placeholder: 'Cause',
        label: 'Cause',
        error: {
          required: "Veuillez spécifier la cause de l'alerte",
        },
      },
      effect: {
        placeholder: 'Impact',
        label: 'Impact',
        error: {
          required: "Veuillez spécifier l'impact de l'alerte",
        },
      },
      period: {
        title: 'Période de perturbation',
        'start-date': {
          label: 'Date de début',
          error: {
            required: 'Veuillez spécifier une date de début pour la période de perturbation',
          },
          help: 'Choisissez ici quand commence la perturbation. Vous définirez quand rendre l’alerte visible aux voyageurs à l’étape 4',
        },
        'end-date': {
          label: 'Date de fin',
          help: 'L’alerte ne sera plus visible une fois cette date passée. Vous pouvez laisser le champ vide et mettre à jour / clôturer l’alerte plus tard. ',
          error: {
            beforeNow: 'Veuillez spécifier une date ultérieure à la date actuelle',
            beforeStartDate: 'Veuillez spécifier une date ultérieure à la date de début',
            incomplete: 'Veuillez compléter la date de fin',
          },
        },
      },
      summary: {
        object: 'Objet :',
        externalLink: 'Fichier ou URL associés:',
        broadcastLater: 'Programmer la diffusion',
        broadcast: 'Diffuser maintenant',
        error: {
          END_DATE_BEFORE_TODAY:
            'La date de fin de perturbation est antérieure à la date actuelle.',
          ALERT_ALREADY_PUBLISHED: "L'alerte a déjà été diffusée",
          END_DATE_BEFORE_BROADCAST_DATE:
            'La date de fin de perturbation est antérieure à la date de diffusion',
          BROADCAST_DATE_BEFORE_TODAY: 'La date de diffusion est antérieure à la date actuelle',
        },
        channels: {
          name: 'Canal',
          message: 'Message',
        },
      },
      next: 'Suivant',
    },
    'alert-detail': {
      title: "Détail de l'alerte",
      'broadcast-history': {
        title: 'Diffusions précédentes',
        'title-future': 'Diffusion à venir',
        panel: {
          header: {
            INITIAL: 'Première diffusion - {date}',
            ADDITIONAL: 'Mise à jour  - {date}',
          },
          body: {
            object: 'Objet :',
            externalLink: 'Fichier ou URL associés:',
            channel: 'Canal',
            message: 'Message',
            'broadcast-status': "Statut d'envoi",
          },
        },
      },
    },
    'alert-import': {
      title: 'Import de fichier',
      steps: {
        publish: 'Importer',
        'select-a-file': 'Sélectionner un fichier',
      },
      publish: {
        agency: 'Agence',
        cancel: "Annuler l'import",
        publish: 'Confirmer l’import',
        confirm: {
          message: 'Êtes-vous sûr de vouloir importer les alertes pour les agences suivantes ?',
          confirm: 'Confirmer',
          cancel: 'Annuler',
          'error-message': 'Il y a eu une erreur avec votre import',
          help: 'Veuillez contacter votre administrateur ou le support IT',
          successTitle: 'Les alertes ont été importées avec succès sur Notify !',
          successMessage:
            'Vous pouvez mettre à jour ou fermer ces alertes via un nouvel import de fichier',
          ok: 'Ok',
        },
        effect: 'Impact',
        endDate: 'Fin de perturbation',
        importType: "Type d'import",
        location: 'Ligne {row}, colonne {column} : ',
        modal: {
          'created-alerts': '{current}/{final} alertes ont été créées',
          'updated-alerts': '{current}/{final} alertes ont été mises à jour',
          'closed-alerts': '{current}/{final} alertes ont été clôturées',
          'unchanged-alerts': '{current}/{final} alertes sont restées inchangées',
        },
        'imported-alerts':
          '{count, plural, =0 {Aucune alerte ne sera importée} one {1 alerte sera importée} other {{count} alertes seront importées}}',
        'updated-alerts':
          '{count, plural, =0 {Aucune alerte ne sera modifiée} one {1 alerte sera modifiée} other {{count} alertes seront modifiées}}',
        'closed-alerts':
          '{count, plural, =0 {Aucune alerte ne sera clôturée} one {1 alerte sera clôturée} other {{count} alertes seront clôturées}}',
        'not-changed-alerts':
          '{count, plural, =0 {Aucune alerte sera modifiée} one {1 alerte ne sera pas modifiée} other {{count} alertes ne seront pas modifiées}}',
        'new-alerts':
          '{count, plural, =0 {Aucune nouvelle alerte ne sera créée} one {1 nouvelle alerte sera créée} other {{count} nouvelles alertes seront créées}}',
        replace: 'Remplacer le fichier',
        results: 'Résultats',
        routes: 'Lignes',
        rowNumber: 'Ligne du fichier',
        startDate: 'Début de perturbation',
        problem: 'Problème',
        problems: {
          'broadcast-date-in-past': 'La date de diffusion ne peut pas être dans le passé',
          'end-date-before-start-date': 'La date de fin doit être après la date de début',
          'subject-too-long': "L'objet est trop long. Maximum 60 caractères.",
          'description-too-long': 'La description est trop longue. Maximum 1200 caractères.',
          'missing-date': "La date ou l'heure est manquante",
          'missing-description': 'La description est manquante.',
          'missing-effect': "L'impact est manquant.",
          'missing-cause': 'La cause est manquante.',
          'invalid-agency':
            "L'agence saisie n'existe pas ou vous n'avez pas le droit d'importer des alertes pour l'agence indiquée",
          'invalid-date': "Le format de la date ou de l'heure n'est pas reconnu.",
          'invalid-route':
            'Une ou plusieurs lignes ne sont pas reconnues pour l’agence sélectionnée.',
          'agency-not-selected':
            "L'agence n'est pas incluse dans le périmètre d’agences sélectionnées",
          'missing-agency-id': "L'agence est manquante",
          'missing-subject': "L'object est manquant",
          'missing-routes': 'Ligne(s) manquante(s)',
          'invalid-cause': "La cause n'existe pas",
          'invalid-effect': "L'impact n'existe pas",
        },
        'problems-detected':
          '{count} {count, plural, one {problème a été détecté. Sauf en le corrigeant, ces alertes ne seront pas importées} other {problèmes ont été détectés. Sauf en les corrigeant, ces alertes ne seront pas importées}}',
      },
      'select-agencies': {
        label: "Sélectionner les agences impactées par l'import",
      },
      'select-file': {
        button: 'Sélectionner un fichier',
        'instructions-0': 'Importer des alertes :',
        'instructions-1': '<b>Étape 1:</b> Sélectionner les agences concernées par l’import ',
        'instructions-2':
          '<b>Étape 2:</b> Charger un fichier XLS et corriger les éventuels problèmes ',
        'instructions-3': '<b>Étape 3:</b> Confirmer la mise à jour',
      },
      upload: {
        button: 'Charger le fichier',
      },
    },
    'alert-update': {
      title: "Mise à jour de l'alerte",
      'access-button': "Mettre à jour l'alerte",
      'restart-button': "Relancer l'alerte",
      'close-button': "Clôturer l'alerte",
      broadcastLater: 'Programmer la diffusion',
      broadcast: 'Diffuser maintenant',
      'file-import': ' – Import fichier',
      'start-date': {
        error: {
          required: 'Veuillez spécifier une date de début pour la période de perturbation',
        },
      },
      'end-date': {
        error: {
          incomplete: 'Veuillez compléter la date de fin',
          beforeStartDate: 'Veuillez spécifier une date ultérieure à la date de début',
          beforeCurrentDate: 'Veuillez spécifier une date ultérieure à la date actuelle',
          beforeBroadcastDate:
            'Veuillez spécifier une date de diffusion ultérieure à la date de fin',
        },
      },
      'close-modal': {
        error: "Une erreur s'est produite lors de la clôture de l'alerte",
        confirm: 'Confirmer',
        cancel: 'Annuler',
        question:
          "Êtes-vous sûr de vouloir clôturer l'alerte ? Celle-ci ne sera plus visible sur le site, l'application mobile et Google Maps",
      },
      information: "Informations sur l'alerte",
      routes: 'Lignes impactées',
      channels: 'Canaux de communication',
      summary: 'Validation',
      'confirm-broadcast-question': 'Confirmez-vous la diffusion de cette mise à jour ?',
      'confirm-broadcast': 'Oui',
      'cancel-broadcast': 'Non',
      error: {
        INVALID_ROUTE_ID: "L'une des ligne indiquée n'existe pas ou plus dans le réseau",
        TWITTER_MESSAGE_TOO_LONG: 'Le message Twitter doit faire moins de 280 caractères',
      },
    },
    'alert-list': {
      create: 'Créer une nouvelle alerte',
      createTemplate: 'Modèles',
      'empty-list': 'Aucune alerte',
      importAlerts: 'Importer des alertes',
      filters: {
        future: 'Alertes programmées',
        'on-going': 'Alertes en cours',
        completed: 'Alertes passées',
        draft: 'Brouillons',
      },
      'delete-draft': {
        confirm: 'Confirmer',
        cancel: 'Annuler',
        'question-draft': 'Êtes-vous sûr de vouloir supprimer le brouillon ?',
        question: "Êtes-vous sûr de vouloir supprimer l'alerte ?",
      },
      agencies: {
        label: 'Agence',
      },
      routes: {
        label: 'Ligne',
        network: 'Tout le réseau',
        type: 'Réseau {type}',
      },
      title: 'Tableau des alertes',
      number: 'N°',
      'last-broadcast': 'Dernière diffusion',
      'broadcast-status': "Statut d'envoi",
      actions: 'Actions',
      'insufficient-permissions':
        'Vous ne pouvez pas modifier cette alerte car elle concerne également d’autres agences pour lesquelles vous n’avez pas les droits.',
      preview: {
        seeMore: 'Voir plus',
      },
    },
    'alert-template-list': {
      title: "Modèles d'alertes ",
      name: 'Nom',
      creationDate: 'Date de création',
      modificationDate: 'Dernières modifications',
      createdBy: 'Créateur',
      actions: 'Actions',
      create: "Créer un modèle d'alerte",
      'copy-modal': {
        error: "Une erreur s'est produite lors de la duplication du modèle d'alerte",
        confirm: 'Confirmer',
        cancel: 'Annuler',
        question: "Voulez-vous dupliquer ce modèle d'alerte ?",
      },
      'delete-modal': {
        error: "Une erreur s'est produite lors de la suppression du modèle d'alerte",
        confirm: 'Confirmer',
        cancel: 'Annuler',
        question: "Êtes-vous sûr de vouloir supprimer ce modèle d'alerte ?",
      },
    },
    'alert-template-creation': {
      title: "Création d'un modèle d'alerte",
      submit: 'Enregistrer le modèle',
      error: {
        EMPTY_NAME: 'Veuillez renseigner le nom du modèle',
        INVALID_NAME: 'Un modèle sous le même nom existe déjà',
        DEFAULT_ERROR: "Une erreur s'est produite lors de la création du modèle",
      },
    },
    'alert-template-edition': {
      title: 'Modèle',
      submit: 'Sauvegarder les modifications',
      'use-button': 'Créer une alerte à partir de ce modèle',
      error: {
        EMPTY_NAME: 'Veuillez renseigner le nom du modèle',
        INVALID_NAME: 'Un modèle sous le même nom existe déjà',
        DEFAULT_ERROR: "Une erreur s'est produite lors de la mise à jour du modèle",
      },
    },
    'network-creation': {
      title: 'Informations sur le réseau',
      network: {
        label: 'Nom du réseau',
        placeholder: 'Réseau',
        error: {
          required: 'Veuillez sélectionner un réseau',
        },
      },
      submit: 'Créer le réseau',
    },
    'network-list': {
      create: 'Créer un nouveau réseau',
      title: 'Mes réseaux',
      name: 'Réseau',
      creationDate: 'Date de création',
    },
    settings: {
      title: 'Paramètres du réseau',
      users: {
        title: 'Gestion des utilisateurs',
        'empty-list': 'Aucun utilisateur',
        'first-name': 'Prénom',
        'last-name': 'Nom',
        email: 'Email',
        role: 'Rôle',
        actions: 'Actions',
        superadmin: 'Super Admin',
        admin: 'Admin',
        operator: 'Opérateur',
        agencies: 'Agences',
        'all-agencies': 'Tout le réseau',
      },
      update: {
        'modal-title': 'Modifier l’utilisateur',
        'ok-text': 'Valider',
      },
      invitations: {
        title: 'Invitations en attente',
        create: 'Inviter un utilisateur',
        'empty-list': 'Aucun invité',
        email: 'Email',
        role: 'Rôle',
        actions: 'Actions',
      },
      invite: {
        'modal-title': 'Inviter un nouvel utilisateur',
        ok: 'Inviter',
      },
      error: {
        'agency-required': 'Au moins une agence doit être sélectionnée',
        required: "L'email est requis",
        'invalid-email': "L'email renseigné n'est pas valide",
        ALREADY_EXISTS: "L'utilisateur fait déjà partie du réseau",
        ALREADY_INVITED: "L'utilisateur a déjà été invité à ce réseau",
        IS_SUPERADMIN: "L'utilisateur est SuperAdmin, il peut déjà accéder à ce réseau",
      },
    },
  },
  utils: {
    date: {
      today: 'aujourd’hui',
      tomorrow: 'demain',
      yesterday: 'hier',
      format: 'DD/MM/YYYY',
      placeholder: 'JJ/MM/AAAA',
    },
    time: {
      format: 'HH:mm',
      placeholder: 'HH:MM',
    },
  },
};

export default frTranslations;
