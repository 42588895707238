/**
 * App spacing measurement convention
 * Use the getSpacing function below to compute padding and margin
 */
const SPACING_UNIT = 5;
const MEASUREMENT_UNIT = 'px';

/**
 * Do not use directly the colorPalette in your components
 * Create an entry in the colorUsage below instead
 */
const colorPalette = {
  turquoise: '#00a0be',
  orange: '#d75a19',
  lightOrange: '#faad14',
  white: '#ffffff',
  lighterGrey: '#f8f8f8',
  lightGrey: '#d9d9d9',
  darkGrey: '#777777',
  green: '#52c41a',
  red: '#F5222D',
  black: '#000000d9',
  lightBlue: '#ccecf2b2',
};

/**
 * Use this dictionary in your components
 * Define a new key each time you use a color if it's for a different use
 * Ex: fill, border-color, background-color, color ...
 */
export const colorUsage = {
  primary: colorPalette.turquoise,
  header: {
    background: {
      default: colorPalette.turquoise,
      local: colorPalette.lightOrange,
      preprod: colorPalette.orange,
      prod: colorPalette.turquoise,
    },
  },
  border: colorPalette.lightGrey,
  breadcrumb: {
    icon: {
      background: {
        active: colorPalette.orange,
        default: colorPalette.lightGrey,
      },
    },
  },
  checkbox: colorPalette.turquoise,
  icon: { info: colorPalette.turquoise, container: { background: colorPalette.lightBlue } },
  title: {
    h1: colorPalette.darkGrey,
    h2: colorPalette.orange,
    h3: colorPalette.turquoise,
  },
  text: {
    coloredBackground: colorPalette.white,
    whiteBackground: colorPalette.darkGrey,
    disclaimer: colorPalette.lightGrey,
    error: colorPalette.red,
    link: colorPalette.turquoise,
  },
  button: {
    primary: {
      background: colorPalette.orange,
      hover: colorPalette.white,
      disabled: colorPalette.lightGrey,
    },
    secondary: {
      backgroundSelected: colorPalette.turquoise,
      backgroundUnselected: colorPalette.white,
    },
    delete: colorPalette.red,
    edit: {
      backgroundSelected: colorPalette.lightOrange,
    },
    tertiary: {
      backgroundSelected: colorPalette.darkGrey,
      backgroundUnselected: colorPalette.white,
    },
    upload: {
      background: colorPalette.lighterGrey,
    },
  },
  collapse: {
    header: {
      text: colorPalette.darkGrey,
      icon: colorPalette.turquoise,
    },
  },
  loader: {
    wheel: colorPalette.orange,
  },
  body: {
    background: colorPalette.white,
  },
  table: {
    header: {
      background: colorPalette.orange,
    },
    row: {
      disabled: colorPalette.lighterGrey,
      selected: colorPalette.white,
    },
  },
  tag: {
    primary: {
      background: colorPalette.turquoise,
      text: colorPalette.white,
      border: colorPalette.turquoise,
    },
    secondary: {
      background: colorPalette.white,
      text: colorPalette.black,
      border: colorPalette.lightGrey,
    },
  },
  tooltip: {
    background: colorPalette.white,
    text: colorPalette.black,
  },
  statusColors: {
    ON_GOING: colorPalette.lightOrange,
    PROGRAMMED: colorPalette.darkGrey,
    SENT: colorPalette.green,
    SENT_KO: colorPalette.orange,
    SKIPPED: colorPalette.darkGrey,
    ERROR: colorPalette.red,
    DRAFT: colorPalette.darkGrey,
    CANCELED: colorPalette.red,
  },
};

export const fontFamily = {
  main: `'Montserrat', sans-serif`,
};

export const fontSize = {
  XXLarge: '60px',
  Xlarge: '30px',
  large: '24px',
  ample: '18px',
  medium: '16px',
  small: '14px',
  XSmall: '12px',
};

export const fontWeight = {
  bold: '700',
  medium: '500',
  normal: '400',
  light: '300',
};

export const lineHeight = {
  large: '36px',
  medium: '22px',
  small: '12px',
};

export const borderRadius = {
  small: '2px',
  medium: '4px',
  large: '10px',
};

export const border = {
  small: '1px',
  medium: '2px',
};

const size = {
  mobile: '500px',
  tablet: '700px',
  desktop: '1200px',
};

export const device = {
  mobile: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  desktop: `(min-width: ${size.desktop})`,
};

export const getSpacing = (multiplier: number): string =>
  `${multiplier * SPACING_UNIT}${MEASUREMENT_UNIT}`;
