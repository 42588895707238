import { defaultTheme } from '@ant-design/compatible';
import { ConfigProvider } from 'antd';
import { Locale } from 'antd/es/locale';
import arEG from 'antd/es/locale/ar_EG';
import enGB from 'antd/es/locale/en_GB';
import enUS from 'antd/es/locale/en_US';
import frBE from 'antd/es/locale/fr_BE';
import frFR from 'antd/es/locale/fr_FR';
import itIT from 'antd/es/locale/it_IT';
import dayjs from 'dayjs';
import merge from 'lodash/merge';
import { ReactNode } from 'react';
import { RawIntlProvider } from 'react-intl';

import { intl, NAVIGATOR_LANGUAGE, NAVIGATOR_LOCALE } from 'services/translations';
import { colorUsage, fontFamily } from 'stylesheet';

import { DirectionContext } from './Contexts';

/**
 * Include both exact locale file (fr-FR for exemple) as well as language fallback (if en-CA is asked, falling back to en value)
 */
export const antdLocales: Record<string, Locale> = {
  fr: merge({}, enUS, frFR),
  'fr-FR': merge({}, enUS, frFR),
  'fr-BE': merge({}, enUS, frBE),
  it: merge({}, enUS, itIT),
  'it-IT': merge({}, enUS, itIT),
  en: merge({}, enUS, enGB),
  'en-GB': merge({}, enUS, enGB),
  'en-US': enUS,
  ar: merge({}, enUS, arEG),
};

const StyleAndIntlConfig = ({ children }: { children?: ReactNode | undefined }): JSX.Element => {
  const locale = antdLocales[NAVIGATOR_LOCALE] ?? antdLocales[NAVIGATOR_LANGUAGE] ?? enUS;
  dayjs.locale(locale.locale);
  const direction = locale.locale === 'ar' ? 'rtl' : 'ltr';

  return (
    <DirectionContext.Provider value={direction}>
      <ConfigProvider
        locale={locale}
        direction={direction}
        theme={{
          ...defaultTheme,
          token: {
            ...defaultTheme.token,
            fontFamily: fontFamily.main,
            colorPrimary: colorUsage.primary,
          },
        }}
      >
        <RawIntlProvider value={intl}>{children}</RawIntlProvider>
      </ConfigProvider>
    </DirectionContext.Provider>
  );
};

export default StyleAndIntlConfig;
