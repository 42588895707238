/* eslint-disable max-lines */
import { BroadcastChannel } from 'types/broadcastChannels';

import { Translations } from './types';

const itTranslations: Translations = {
  languages: {
    fr: 'Francese',
    en: 'Inglese',
    it: 'Italiano',
    br: 'Bretone',
    ar: 'Arabo',
  },
  alert: {
    number: 'Numero di avviso',
    'creation-date': 'Data di creazione',
    'created-by': 'Creatore dell’avviso',
    cause: {
      label: 'Causa',
      ACCIDENT: 'INCIDENTE',
      TECHNICAL_PROBLEM: 'PROBLEMA TECNICO',
      MAINTENANCE: 'MANUTENZIONE',
      CONSTRUCTION: 'LAVORI STRADALI',
      STRIKE: 'SCIOPERO',
      DEMONSTRATION: 'MANIFESTAZIONE',
      WEATHER: 'EVENTI METEREOLOGICI',
      HOLIDAY: 'SERVIZIO RIDOTTO',
      POLICE_ACTIVITY: 'INTERVENTO POLIZIA',
      MEDICAL_EMERGENCY: 'EMERGENZA MEDICA',
      OTHER_CAUSE: 'ALTRE CAUSE',
      UNKNOWN_CAUSE: 'CAUSE SCONOSCIUTE',
    },
    effect: {
      label: 'Impatto',
      NO_SERVICE: 'NESSUN SERVIZIO',
      SIGNIFICANT_DELAYS: 'POSSIBILI RITARDI',
      DETOUR: 'DEVIAZIONE',
      REDUCED_SERVICE: 'SERVIZIO RIDOTTO',
      STOP_MOVED: 'MODIFICA DI FERMATA',
      MODIFIED_SERVICE: 'SERVIZIO MODIFICATO',
      ADDITIONAL_SERVICE: 'INCREMENTO DEL SERVIZIO',
      OTHER_EFFECT: 'INFORMAZIONI',
      UNKNOWN_EFFECT: 'POSSIBILI DISAGI',
    },
    'import-type': {
      new: 'nuovo',
      update: 'aggiornamento',
      closure: 'chiusura',
      no_change: 'nessuna modifica',
      problem: 'problema',
      processing: 'in corso',
      ready_to_import: 'ok',
    },
    'start-date': 'Inizio interruzione',
    'end-date': 'Fine interruzione',
    'route-type': {
      '0': 'Tram',
      '1': 'Metro',
      '2': 'Treno',
      '3': 'Autobus',
      '4': 'Traghetto',
      '5': 'Tramway à traction par câble',
      '6': 'Télécabine ou téléphérique',
      '7': 'Funicolare',
      '100': 'Service de chemin de fer',
      '101': 'Service de chemin de fer à grande vitess',
      '102': 'Grandes lignes de chemin de fer',
      '103': 'Service de chemin de fer transrégional',
      '104': 'Service de transport de voitures par chemin de fer',
      '105': 'Service de chemin de fer à couchettes',
      '106': 'Service de chemin de fer régional',
      '107': 'Service de chemin de fer touristique',
      '108': "Navette ferroviaire (au sein d'un complexe)",
      '109': 'Chemin de fer de banlieue',
      '110': 'Service de chemin de fer de substitution',
      '111': 'Service de chemin de fer spécial',
      '112': 'Service de transport de camions par chemin de fer',
      '113': 'Service de chemin de fer (toute catégorie)',
      '114': 'Service de chemin de fer transnational',
      '115': 'Service de transport de véhicules par chemin de fer',
      '116': 'Chemin de fer à crémaillère',
      '117': 'Service de chemin de fer supplémentaire',
      '200': 'Service de cars',
      '201': 'Service de cars internationaux',
      '202': 'Service de cars nationaux',
      '203': 'Service de navette par cars',
      '204': 'Service de cars régionaux',
      '205': 'Service de cars spéciaux',
      '206': 'Service de visite touristique en car',
      '207': 'Service de cars touristiques',
      '208': 'Service de cars de banlieue',
      '209': 'Service de cars (toute catégorie)',
      '400': 'Service de chemin de fer urbain',
      '401': 'Service de chemin de fer métropolitain',
      '402': 'Service de transport souterrain',
      '403': 'Service de chemin de fer urbain',
      '404': 'Service de chemin de fer urbain (toute catégorie)',
      '405': 'Monorail',
      '700': "Service d'autobus",
      '701': "Service d'autobus régional",
      '702': "Service d'autobus express",
      '703': "Service d'autobus desservant des arrêts",
      '704': "Service d'autobus local",
      '705': "Service d'autobus nocturne",
      '706': 'Service de car postal',
      '707': "Service d'autobus pour passagers présentant des besoins spéciaux",
      '708': "Service d'autobus pour personnes à mobilité réduite",
      '709': "Service d'autobus pour personnes à mobilité réduite (déclarées comme telles)",
      '710': 'Autobus panoramique',
      '711': 'Service de navette par autobus',
      '712': 'Bus scolaire',
      '713': 'Bus scolaire et à usage public',
      '714': "Service de substitution par autobus (remplacement d'un service de chemin de fer)",
      '715': 'Autobus à la demande',
      '716': "Service d'autobus (toute catégorie)",
      '717': 'Service de taxi partagé',
      '800': 'Service de trolleybus',
      '900': 'Service de tramway',
      '901': 'Service de tramway urbain',
      '902': 'Service de tramway local',
      '903': 'Service de tramway régional',
      '904': 'Service de tramway touristique',
      '905': 'Service de navette par tramway',
      '906': 'Service de tramway (toute catégorie)',
      '907': 'Tramway à traction par câble',
      '1000': 'Service de transport fluvial',
      '1100': 'Service de transport aérien',
      '1200': 'Service de ferry',
      '1300': 'Service de téléphérique',
      '1400': 'Service de funiculaire',
      '1500': 'Service de taxis',
      '1501': 'Service de taxis communaux',
      '1502': 'Service de taxi fluvial',
      '1503': 'Service de taxis en gare de chemin de fer',
      '1504': 'Service de vélotaxi',
      '1505': 'Service de taxis réglementé',
      '1506': 'Service de location de véhicules particuliers',
      '1507': 'Service de taxis (toute catégorie)',
      '1700': 'Services divers',
    },
    channel: {
      EMAIL: 'Campagna via e-mail',
      SMS: 'Campagna via SMS',
      TRANSACTIONAL_SMS: 'SMS',
      TRANSACTIONAL_EMAIL: 'E-mail',
      GTFS_RT: 'Sito web + App mobile + Google Maps',
      TWITTER: 'X/Twitter',
      PUSH: 'Notifica push',
      WEBSITE_BANNER: 'Banner del sito web',
    },
    channelContact: {
      TRANSACTIONAL_SMS: 'messaggio',
      TRANSACTIONAL_SMS_PLURAL: 'messaggi',
      TRANSACTIONAL_EMAIL: 'email',
      TRANSACTIONAL_EMAIL_PLURAL: 'email',
    },
    broadcastStatus: {
      DRAFT: 'Bozza',
      ON_GOING: 'In corso',
      PROGRAMMED: 'Futuro',
      SENT: 'Inviato',
      SKIPPED: 'Saltato',
      ERROR: 'Problema',
      CANCELED: 'Annullato',
    },
    broadcastStatusTooltip: {
      SKIPPED: {
        TWITTER:
          'Questo aggiornamento non è stato trasmesso su Twitter perché il contenuto di questo canale era lo stesso della trasmissione precedente',
        TRANSACTIONAL_SMS:
          'Questo aggiornamento non è stato trasmesso via SMS poiché nessuno è iscritto a questo canale',
        TRANSACTIONAL_EMAIL:
          'Questo aggiornamento non è stato trasmesso via e-mail poiché nessuno è iscritto a questo canale',
      },
    },
  },
  component: {
    header: {
      title: 'DAIT',
    },
    'alert-information-overview': {
      title: 'Informazioni sull’avviso',
    },
    'alert-routes-overview': {
      title: 'Linee interessate',
    },
    'routes-selection': {
      'select-all': 'Seleziona tutto',
      title: 'Linee',
      error: {
        required: 'Inserire almeno una linea',
      },
      'see-routes': 'Visualizza linee',
      'select-routes': '{routeType} – Seleziona le linee interessate',
      validate: 'Convalida',
      'route-types': 'Modalità di trasporto',
    },
    'alert-routes-card': {
      'no-route-selected': 'Nessuna linea selezionata',
    },
    'alert-template-form': {
      'template-name': {
        title: 'Nome modello',
        name: {
          label: 'Nome',
          error: {
            EMPTY_NAME: 'Inserire un nome',
            INVALID_NAME: 'Inserire un nome diverso',
          },
        },
      },
      'alert-information': {
        title: 'Informazioni sull’avviso',
        cause: {
          placeholder: 'Causa',
          label: 'Causa',
        },
        effect: {
          placeholder: 'Impatto',
          label: 'Impatto',
        },
      },
      channels: {
        title: 'Canali di comunicazione',
      },
    },
    'channels-configuration': {
      'delete-language': 'Elimina',
      'confirm-deletion': 'Conferma',
      'cancel-deletion': 'Annulla',
      'confirm-deletion-text': 'Sei sicuro di voler eliminare questa lingua?',
      'add-language': 'Aggiungi una nuova lingua di trasmissione',
      'alert-content': {
        'title-first': "Contenuto dell'allerta",
        'title-other': 'Content of your message (lingua {index})',
        'translation-label': 'Generare automaticamente una traduzione',
        'translation-warning':
          "Attenzione! Le traduzioni automatiche possono talvolta contenere errori. È quindi importante controllare il contenuto prima di trasmettere l'avviso.",
        'translation-error':
          'Il servizio di traduzione automatica non è disponibile. Si prega di riprovare più tardi o di utilizzare le soluzioni di traduzione alternative.',
        'translation-truncated':
          'La traduzione proposta supera il numero di caratteri consentito. È quindi importante controllare il contenuto prima di trasmettere la segnalazione.',
      },
      enrich: 'Canale {channel} – Modificare',
      language: {
        label: 'Lingua di trasmissione',
        error: {
          required: 'Aggiungere una lingua di trasmissione per questo avviso',
        },
      },
      save: 'Salva',
      object: {
        label: 'Oggetto',
        help: 'Questo campo verrà utilizzato come oggetto dell’e-mail, titolo della notifica push e titolo dell’avviso trasmesso sul sito Web, sull’applicazione mobile e sullo stream GTFS-RT.',
        error: {
          required: 'Inserire un oggetto per questo avviso',
          'max-length': 'Inserire un oggetto contenente meno di 6 caratteri',
        },
      },
      [BroadcastChannel.WEBSITE_BANNER]: {
        label: 'Contenuto Banner del sito web ({language})',
        note: 'Configura e crea un banner sul tuo sito web.',
        bannerTitle: {
          label: 'Titolo del banner',
          description: 'Messaggio visibile sul banner delle informazioni',
        },
        pageTitle: {
          label: 'Titolo',
          description: 'Titolo della pagina associata al banner delle informazioni',
        },
        bannerContent: {
          label: 'Descrizione',
          description: 'Contenuto della pagina associata al banner delle informazioni',
        },
      },
      [BroadcastChannel.TRANSACTIONAL_EMAIL]: {
        label: "Contenuto dell'e-mail ({language})",
        note: 'Modifica il contenuto e configura una email di prova, ricevi la tua notifica come sarà vista dai tuoi utenti.',
        test: {
          error: 'Fallito!',
          errorDetails: "L'e-mail non è stata inviata. Si prega di riprovare.",
          sending: 'Invio in corso...',
          sendTest: 'Ricevi una email di prova',
          sent: 'Inviato!',
          sentDetails: 'La tua email di prova è disponibile nella tua casella di posta',
        },
      },
      'short-description': {
        label: 'Descrizione breve (SMS, Notifiche push)',
        error: {
          required: 'Inserire il contenuto dell’avviso',
          'max-length': 'Inserire una descrizione breve contenente meno di 152 caratteri',
        },
      },
      'long-description': {
        label:
          'Descrizione lunga (sito web + app mobile + Google Maps, e-mail, Twitter, Pagina banner del sito web)',
        error: {
          required: 'Inserire il contenuto dell’avviso',
          'max-length': 'Inserire una descrizione lunga contenente meno di 1200 caratteri ',
        },
        note: 'Tenere presente che è possibile formattare il contenuto dell’avviso nel canale e-mail. A tale scopo, selezionare il canale e-mail nei canali di comunicazione sottostanti e cliccare sull’icona di modifica a destra della tabella.',
      },
      'twitter-enrich-subtext':
        "Arricchite le vostre pubblicazioni su Twitter creando post collegati per aggiornare l'avviso o aggiungendo immagini all'avviso.",
      'twitter-history-title': 'Cronologia dei tweet',
      'twitter-history-empty': 'Non è stato ancora pubblicato alcun tweet. Creare un nuovo tweet.',
      'twitter-description': {
        label: 'Nuovo tweet',
        error: {
          required: 'Inserire il contenuto dell’avviso',
          'max-length': 'Inserire una descrizione contenente meno di 280 caratteri ',
        },
      },
      'twitter-image-description': {
        label: 'Aggiungere una descrizione',
        note: "Migliorate l'accessibilità e la visibilità del vostro post aggiungendo una descrizione alla vostra immagine!",
      },
      'rich-text': {
        'link-title': 'Inserisci un link',
        'link-name': 'Testo da visualizzare',
        'link-target': 'Link',
        insert: 'Inserisci',
      },
      upload: {
        title: 'Aggiungere un documento o un link',
        label: 'Aggiungere un file PDF',
        button: 'Caricare un file',
        copyInfo: "L'URL viene copiato negli appunti.",
        copyError: 'Il copia-incolla non ha funzionato.',
        tip1: 'Se carichi un documento, questo verrà automaticamente inserito nel canale "Sito web + App mobile + Google Maps".',
        tip2: 'Se vuoi unirlo ad altri canali, copia e incolla l’URL della mappa nel contenuto dei canali di tua scelta.',
        linkLabel: 'Aggiungere un link URL',
        linkPlaceholder: 'https://example.com',
        linkTip: "Questo link sarà aggiunto al contenuto dell'avviso inviato ai vari media.",
        tooltip:
          'Aggiungete un file o un URL al vostro avviso. Questo elemento sarà disponibile in tutti i media. È possibile incorporare solo un link o un file.',
        'invalid-url': 'Grazie di inserire un URL valido',
      },
      uploadImage: {
        button: 'Cliccare per aggiungere un file',
        label: "Aggiungere un'immagine",
        supportedFiles: 'File supportati: JPEG, JPG, PNG',
        size: 'Le immagini vengono visualizzate in rapporto 16:9, ovvero a 600px per 335px, nel feed di Twitter. Quando si fa clic su di esse, vengono ingrandite a 1.200px per 675px.',
        sizeError: 'Il file supera le dimensioni massime consentite (5 MB).',
      },
      channels: {
        label: 'Gestione dei canali di comunicazione',
        'twitter-multilingual-error':
          'Il messaggio può essere trasmesso solo su Twitter in {defaultLanguage}',
        error: {
          required: 'Selezionare almeno un canale di trasmissione',
        },
        'push-error':
          'Per trasmettere l’avviso sul canale “Notifica push” è necessario selezionare il canale "Sito web + Mobile App + Google Maps".',
        empty: 'Nessun canale disponibile',
        enrich: 'Modificare',
        info: 'In questa sezione è possibile gestire e modificare il contenuto di tutti i canali di comunicazione prima che l\'avviso venga trasmesso. Cliccate su "Arricchisci" per gestire il contenuto di ogni canale!',
        name: 'Canale',
        message: 'Messaggio',
        'message-type': {
          SHORT: 'Breve',
          LONG: 'Lungo',
          CUSTOM: 'Personalizzato',
        },
        update: 'Azioni',
      },
    },
    'broadcast-date-configuration': {
      'confirm-programmation': 'Conferma',
      'cancel-programmation': 'Annulla',
      title: 'Programma la trasmissione dell’avviso',
      'broadcast-before-today': 'Selezionare un’ora e una data in futuro',
      'broadcast-after-end': 'Programmare l’avviso prima della data di fine interruzione',
    },
  },
  page: {
    'alert-creation': {
      'template-selection': {
        'use-template': 'Usa un modello',
        confirm: 'Conferma',
        content: 'I passaggi 1 e 3 saranno precompilati con le informazioni del modello',
        select: 'Seleziona un modello',
        cancel: 'Annulla',
        errorLabel: 'Selezionare un modello',
      },
      steps: {
        information: 'Informazioni sull’avviso',
        routes: 'Linee interessate',
        channels: 'Canali di comunicazione',
        summary: 'Convalida',
      },
      information: {
        error: {
          ALERT_ALREADY_PUBLISHED: 'L’avviso è già stata emesso',
        },
      },
      'routes-selection': {
        agency: 'Agenzie: ',
        error: {
          ALERT_ALREADY_PUBLISHED: 'L’avviso è già stata emesso',
        },
      },
      'channels-configuration': {
        error: {
          ALERT_ALREADY_PUBLISHED: 'L’avviso è già stata emesso',
          TWITTER_MESSAGE_TOO_LONG: 'Il messaggio Twitter deve contenere meno di 280 caratteri',
        },
      },
      cause: {
        placeholder: 'Causa',
        label: 'Causa',
        error: {
          required: 'Specificare la causa dell’avviso',
        },
      },
      effect: {
        placeholder: 'Impatto',
        label: 'Impatto',
        error: {
          required: 'Specificare la causa dell’avviso',
        },
      },
      period: {
        title: 'Periodo di interruzione',
        'start-date': {
          label: 'Data di inizio',
          error: {
            required: 'Specificare una data di inizio per il periodo di interruzione',
          },
          help: "Questa è la data di inizio dell'interruzione. Puoi scegliere quando l’avviso sarà visibile ai viaggiatori al passaggio 4.",
        },
        'end-date': {
          label: 'Data di fine',
          help: 'L’avviso non sarà più visibile ai viaggiatori in questa data. È possibile lasciarlo vuoto e aggiornare o chiudere manualmente l’avviso in un altro momento',
          error: {
            beforeNow: 'Specificare una data successiva alla data corrente',
            beforeStartDate: 'Specificare una data successiva alla data di inizio',
            incomplete: 'Completare la data di fine',
          },
        },
      },
      summary: {
        object: 'Oggetto:',
        externalLink: 'File o URL collegato:',
        broadcastLater: 'Trasmissione del programma',
        broadcast: 'Trasmetti ora',
        error: {
          END_DATE_BEFORE_TODAY: 'La data di fine interruzione è precedente alla data corrente.',
          ALERT_ALREADY_PUBLISHED: 'L’avviso è già stata emesso',
          END_DATE_BEFORE_BROADCAST_DATE:
            'La data di fine interruzione è precedente alla data di diffusione',
          BROADCAST_DATE_BEFORE_TODAY: 'La data di diffusione è precedente alla data attuale',
        },
        channels: {
          name: 'Canale',
          message: 'Messaggio',
        },
      },
      next: 'Prossimo',
    },
    'alert-detail': {
      title: 'Dettagli dell’avviso',
      'broadcast-history': {
        title: 'Trasmissione precedente',
        'title-future': 'Trasmissione futura',
        panel: {
          header: {
            INITIAL: 'Prima trasmissione - {date}',
            ADDITIONAL: 'Aggiorna - {date}',
          },
          body: {
            object: 'Oggetto:',
            externalLink: 'File o URL collegato:',
            channel: 'Canale',
            message: 'Messaggio',
            'broadcast-status': 'Stato dell’invio',
          },
        },
      },
    },
    'alert-import': {
      title: 'Importazione con file',
      steps: {
        publish: 'Conferma',
        'select-a-file': 'Seleziona un file',
      },
      publish: {
        agency: 'Agenzia',
        cancel: 'Annulla importazione',
        publish: 'Conferma importazione',
        confirm: {
          message: 'Sei sicuro di voler importare gli avvisi per le seguenti agenzie?',
          confirm: 'Conferma',
          cancel: 'Annulla',
          'error-message': 'Si è verificato un errore con l’import',
          help: 'Contatta il tuo amministratore o il supporto IT',
          successTitle: 'Gli avvisi sono stati pubblicati con successo in Notify!',
          successMessage:
            'Puoi aggiornare o chiudere questi avvisi tramite una nuova importazione di file',
          ok: 'Ok',
        },
        effect: 'Impatto',
        endDate: 'Fine interruzione',
        importType: 'Carica stato',
        location: 'Riga del file {row}, colonna {column}: ',
        modal: {
          'created-alerts': '{current}/{final} avvisi sono stati creati',
          'updated-alerts': '{current}/{final} avvisi sono stati aggiornati',
          'closed-alerts': '{current}/{final} avvisi sono stati chiusi',
          'unchanged-alerts': '{current}/{final} non sono stati modificati',
        },
        'imported-alerts':
          '{count} {count, plural, one {aviso verrà creato} other {avvisi verranno creati}}',
        'updated-alerts':
          '{count} {count, plural, one {avviso verrà aggiornato} other {avvisi verranno aggiornati}}',
        'closed-alerts':
          '{count} {count, plural, one {avviso verrà chiuso} other {avvisi verranno chiusi}}',
        'not-changed-alerts':
          '{count} {count, plural, one {avviso non verrà modificato} other {avvisi non verranno modificati}}',
        'new-alerts':
          '{count} {count, plural, one {nuovo aviso verrà creato} other {nuovi avvisi verranno creati}}',
        replace: 'Sostituisci file di importazione',
        results: 'Risultati',
        routes: 'Linee',
        rowNumber: 'Riga del file',
        startDate: 'Inizio interruzione',
        problem: 'Problema',
        problems: {
          'broadcast-date-in-past': 'La data di trasmissione non può essere nel passato',
          'end-date-before-start-date':
            'La data di fine deve essere successiva alla data di inizio',
          'subject-too-long': 'L’oggetto è troppo lungo. Massimo 60 caratteri',
          'description-too-long': 'La descrizione è troppo lunga. Massimo 1200 caratteri',
          'missing-date': 'Data o ora mancante',
          'missing-description': 'Descrizione mancante',
          'missing-effect': 'Impatto mancante',
          'missing-cause': 'Causa mancante',
          'invalid-agency':
            'L’agenzia non esiste o non sei autorizzato a importare gli avvisi per l’agenzia indicata',
          'invalid-date': 'Il formato della data o dell’ora non è stato riconosciuto',
          'invalid-route': 'Non esiste una linea nell’agenzia selezionata',
          'agency-not-selected': 'L’agenzia non è stata selezionata per questo Import',
          'missing-agency-id': 'Agenzia mancante',
          'missing-subject': 'Oggetto mancante',
          'missing-routes': 'Linea/e mancante/i',
          'invalid-cause': 'Causa non esistente',
          'invalid-effect': 'Impatto non esistente',
        },
        'problems-detected':
          '{count} {count, plural, one {problema è stato rilevato} other {problemi sono stati rilevati}}. Se non risolvi i problemi, questi avvisi non verranno importati',
      },
      'select-agencies': {
        label: 'Seleziona le agenzie per questa importazione',
      },
      'select-file': {
        button: 'Seleziona file',
        'instructions-0': 'Importa avvisi',
        'instructions-1':
          '<b>Passaggio 1:</b> seleziona le Agenzie da aggiornare con questa Import',
        'instructions-2':
          '<b>Passaggio 2:</b> carica un file xls e correggi eventuali problemi rilevati',
        'instructions-3': '<b>Passaggio 3:</b> conferma l’import',
      },
      upload: {
        button: 'Avvia Import',
      },
    },
    'alert-update': {
      title: 'Aggiornamento avvisi',
      'access-button': 'Aggiorna avviso',
      'restart-button': 'Riavvia avviso',
      'close-button': 'Chiudi avviso',
      broadcastLater: 'Trasmissione del programma',
      broadcast: 'Trasmetti ora',
      'file-import': ' - File import',
      'start-date': {
        error: {
          required: 'Specificare una data di inizio per il periodo di interruzione',
        },
      },
      'end-date': {
        error: {
          incomplete: 'Completare la data di fine',
          beforeStartDate: 'Specificare una data successiva alla data di inizio',
          beforeCurrentDate: 'Specificare una data successiva alla data corrente',
          beforeBroadcastDate: 'Specificare una data di trasmissione precedente alla data di fine',
        },
      },
      'close-modal': {
        error: 'Si è verificato un errore durante la chiusura dell’avviso',
        confirm: 'Conferma',
        cancel: 'Annulla',
        question:
          "Sei sicuro di voler chiudere questo avviso? Non sarà più visibile sul sito web, sull'applicazione mobile e su Google Maps",
      },
      information: 'Informazioni sull’avviso',
      routes: 'Linee interessate',
      channels: 'Canali di comunicazione',
      summary: 'Convalida',
      'confirm-broadcast-question': 'Confermi la trasmissione di questo aggiornamento?',
      'confirm-broadcast': 'Sì',
      'cancel-broadcast': 'No',
      error: {
        INVALID_ROUTE_ID: 'Una delle linee indicate non esiste o non esiste più nella rete',
        TWITTER_MESSAGE_TOO_LONG: 'Il messaggio Twitter deve contenere meno di 280 caratteri',
      },
    },
    'alert-list': {
      create: 'Crea nuovo avviso',
      createTemplate: 'Modelli di avviso',
      'empty-list': 'Nessun avviso',
      filters: {
        future: 'Avvisi programmati',
        'on-going': 'Avvisi in corso',
        completed: 'Avvisi passati',
        draft: 'Bozze',
      },
      importAlerts: 'Importa avvisi',
      'delete-draft': {
        confirm: 'Conferma',
        cancel: 'Annulla',
        'question-draft': 'Sei sicuro di voler eliminare la bozza?',
        question: 'Sei sicuro di voler eliminare l’avviso?',
      },
      agencies: {
        label: 'Agenzia',
      },
      routes: {
        label: 'Linea',
        network: 'Tutta la rete',
        type: 'Rete {type}',
      },
      title: 'Tabella degli avvisi',
      number: 'N.',
      'last-broadcast': 'Ultima trasmissione',
      'broadcast-status': 'Stato dell’invio',
      actions: 'Azioni',
      'insufficient-permissions':
        'Non puoi aggiornare questo avviso perché include anche linee in Agenzie per le quali non hai l’autorizzazione',
      preview: {
        seeMore: 'Vedi di più',
      },
    },
    'alert-template-list': {
      title: 'Modelli di avviso',
      name: 'Nome',
      creationDate: 'Data di creazione',
      modificationDate: 'Ultima edizione',
      createdBy: 'Creato da',
      actions: 'Azioni',
      create: 'Crea nuovo modello di avviso',
      'copy-modal': {
        error: 'Si è verificato un errore durante la duplicazione del modello di avviso',
        confirm: 'Conferma',
        cancel: 'Annulla',
        question: 'Vuoi duplicare il modello di avviso?',
      },
      'delete-modal': {
        error: 'Si è verificato un errore durante l’eliminazione del modello di avviso',
        confirm: 'Conferma',
        cancel: 'Annulla',
        question: 'Sei sicuro di voler eliminare il modello di avviso?',
      },
    },
    'alert-template-creation': {
      title: 'Creazione del modello di avviso',
      submit: 'Salva modello',
      error: {
        EMPTY_NAME: 'Inserire un nome per il modello',
        INVALID_NAME: 'Esiste già un modello con questo nome',
        DEFAULT_ERROR: 'Si è verificato un errore durante la creazione del modello',
      },
    },
    'alert-template-edition': {
      title: 'Modello',
      submit: 'Salva modifiche',
      'use-button': 'Crea un avviso da questo modello',
      error: {
        EMPTY_NAME: 'Inserire un nome per il modello',
        INVALID_NAME: 'Esiste già un modello con questo nome',
        DEFAULT_ERROR: 'Si è verificato un errore durante l’aggiornamento del modello',
      },
    },
    'network-creation': {
      title: 'Informazioni sulla rete',
      network: {
        label: 'Nome della rete',
        placeholder: 'Rete',
        error: {
          required: 'Selezionare una rete',
        },
      },
      submit: 'Crea rete',
    },
    'network-list': {
      create: 'Crea nuova rete',
      title: 'Le mie reti',
      name: 'Rete',
      creationDate: 'Data di creazione',
    },
    settings: {
      title: 'Parametri della rete',
      users: {
        title: 'Gestione utenti',
        'empty-list': 'Nessun utente',
        'first-name': 'Nome',
        'last-name': 'Cognome',
        email: 'E-mail',
        role: 'Ruolo',
        actions: 'Azioni',
        superadmin: 'Super Amministratore',
        admin: 'Amministratore',
        operator: 'Operatore',
        agencies: 'Agenzia',
        'all-agencies': 'Tutta la rete',
      },
      update: {
        'modal-title': 'Aggiorna utente',
        'ok-text': 'Aggiorna',
      },
      invitations: {
        title: 'Inviti in attesa',
        create: 'Invita un utente',
        'empty-list': 'Nessun utente',
        email: 'E-mail',
        role: 'Ruolo',
        actions: 'Azioni',
      },
      invite: {
        'modal-title': 'Invita un nuovo utente',
        ok: 'Invita',
      },
      error: {
        'agency-required': 'È necessario selezionare almeno 1 agenzia',
        required: 'L’e-mail è obbligatoria',
        'invalid-email': 'L’e-mail non è valida',
        ALREADY_EXISTS: 'L’utente fa già parte della rete',
        ALREADY_INVITED: 'L’utente è già stato invitato a questa rete',
        IS_SUPERADMIN: 'L’utente è Super Amministratore, può già accedere a questa rete',
      },
    },
  },
  utils: {
    date: {
      today: 'oggi',
      tomorrow: 'domani',
      yesterday: 'ieri',
      format: 'DD/MM/YYYY',
      placeholder: 'GG/MM/AAAA',
    },
    time: {
      format: 'HH:mm',
      placeholder: 'OO:MM',
    },
  },
};

export default itTranslations;
